var sg = sg || {};
sg.CheckableList = sg.CheckableList || (function (jQuery)
{
    var CheckableList = function (initValues)
    {        
        var jWindow = jQuery("window");
        var jList = initValues.jList;
        var jListItems = jList.find(".horizontal-container__item");
        var jCheckBox = jList.find("input.toggle-list");
        var jCheckInfoContainer = jList.find(".horizontal-container__info");
        var tooltipTitle = jCheckInfoContainer.attr("title");
        var jTooltips = false;
        
        jQuery.extend(true, this, {
            jWindow: jWindow,
            jList: jList,
            jListItems: jListItems,
            jCheckBox: jCheckBox,
            jCheckInfoContainer: jCheckInfoContainer,
            jTooltips: jTooltips,
            tooltipTitle: tooltipTitle
        });
                
        initView.call(this);
    };
    
    var checkBoxListener = function checkBoxListener()
    {
        var that = this;
        
        that.jCheckBox.on("change", function ()
        {
            //checkCheckBox.call(that);
        });
    };
    
    var initView = function initView()
    {
        checkBoxListener.call(this);
        //checkCheckBox.call(this);
    };
    
    return CheckableList;
}(jQuery));