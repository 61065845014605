var queo = queo || {};

queo.ContactFormController = function (options) {

    var controller = this;
    $.extend(controller, options);

    controller.form.on('submit', function(event){
        event.preventDefault();
        controller.submitContactForm(controller.form.data('submit-url'));
    });

    controller.form.find('input').on('keyup', function(){
        removeContactFormError(this);
    });

};

queo.ContactFormController.prototype.submitContactForm = function(url) {

    var controller = this;

    $.ajax({
        type: "POST",
        url: url,
        data: controller.form.serialize(),
        success: function(data) {
            if(data==="Success"){
                var successMessage = controller.successMessage || '<p>Success</p>';
                controller.form.append(successMessage);
                controller.form.find('button[type="reset"]').click();
            } else {
                var errors = JSON.parse(data);
                errors.forEach(function(error){
                    controller.form.find("."+error.field).addClass("error");
                })
            }
        },
        error: function(error){
            console.log(error);
        }
   });
};

function removeContactFormError(element){
    $(element).removeClass('error');
}