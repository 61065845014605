Array.prototype.sortOn = function(key){
    this.sort(function(a, b){
        if(a[key] < b[key]){
            return -1;
        }else if(a[key] > b[key]){
            return 1;
        }
        return 0;
    });
};

var fastSearchCtrl;

jQuery(document).ready(function(){

    var $fastSearchContainer = $('#tab-pane-fs-turbo');
    var $chartContainer = $('#chart-container-fastsearch');

    if(($fastSearchContainer.length && $chartContainer.length)) {
        fastSearchCtrl = new fastSearchController.Controller({
            ajaxUrlMatrix: "/bcs_ajax/fastsearch/matrix",
            ajaxUrlGraph: "/bcs_ajax/fastsearch/graph",
            detailPageUrl: "/de/product.html?isin=",
            pfPageUrl: "/de/finder.html",
            optionsensopendend: "LONG",
            underlyingName: "",
            language: ENVIRONMENT.language.lowercase,
            chartOptions: {
                lang: ENVIRONMENT.language.lowercase,
                rangeSelectorLabels: {
                    'Intraday': 'Intraday',
                    '1 W': '1 W',
                    '1 M': '1 M',
                    '3 M': '3 M'
                },
                loadingImageUrl: '/.resources/bcs-reloaded-config/files/loading.gif',
                chartContainerId: 'chart-container-fastsearch'
            }
        });

        $('.chart-container .chart-button-group button.chart-button').on('click', function(){

            $('.chart-container .chart-button-group button.chart-button').removeClass('chart-button--active');

            var $this = $(this);
            $this.addClass('chart-button--active');

            var rangeType = $this.data('range-type');
            var range = $this.data('range');

            fastSearchCtrl.updateChart(rangeType, range);

        });
    }
});

var fastSearchController = fastSearchController || {};

fastSearchController.Controller = function(options){
    // Set Ajax Base URL for develop env, for production use system-dependent relative path
    this.baseUrl = ENVIRONMENT.ajax.baseUrl;

    this.ajaxUrlMatrix = options.ajaxUrlMatrix;
    this.ajaxUrlGraph = options.ajaxUrlGraph;
    this.detailPageUrl = options.detailPageUrl;
    this.pfPageUrl = options.pfPageUrl;
    this.language = options.language;
    this.optionsensopendend = options.optionsensopendend;
    this.underlyingName = options.underlyingName;

    this.activeProductGroup = 'turbo';
    this.highlightClass = 'green';
    this.familytypes = [];
    this.jActiveTab = {};

    this.chartOptions = options.chartOptions;
    this.chartController = new sg.FastSearchChart(options.chartOptions);

    this.init();
    this.bindEventListeners();

};

/**
 * Make stuff on initialization of the fastSearchController controller
 */
fastSearchController.Controller.prototype.init = function(tabbed){
    var ctrl = this;

    $('.vertical-slider-wrapper').hide();

    ctrl.setConfgurationForSelectedTab();

    ctrl.initTableContainerScrollbar();

    $('#dropdownMenuLink').val(ctrl.underlyingName);

    ctrl.updateTabbedSearchDropdown(null, function (){
        ctrl.renderSearchResult(null, null, true);
    });

    if(!tabbed) {

        ctrl.renderGraph();
    }
};

/**
 * Set active tab-pane and producttype for ajax calls according to selected tab
 */
fastSearchController.Controller.prototype.setConfgurationForSelectedTab = function(){
    var ctrl = this;

    ctrl.familytypes = [];
    ctrl.jActiveTab = $('[data-fs-tab].tab-pane.active');
    ctrl.activeProductGroup = ctrl.jActiveTab.data('fs-type');
};

fastSearchController.Controller.prototype.bindEventListeners = function(){
    var ctrl = this;
    $('[data-toggle="tab"]').on('shown.bs.tab', function (event) {
        ctrl.jActiveTab.find('.horizontal-container__list').remove();
        ctrl.init(true);
    });

    $('[data-fs-filter]').on('change', function(){
        ctrl.setFilter($(this).data('fs-filter'));
    });

    $('[data-fs-listpage-link]').on('click', function(){
        if(!($(this).hasClass('cta--listview-disabled'))){
            ctrl.openListPage($(this).data('fs-listpage-link'));
        }
    });

    $('[data-fs-wheellist]').on('click', '.slick-slide, .slick-next, .slick-prev', function(){
        ctrl.sliderChanged();
    });

    $(document).on('underlyingChanged', function() {
        $('.vertical-slider-wrapper').hide();
        $('.vertical-slider-wrapper').find('.slick-initialized').slick('unslick');
        ctrl.renderGraph(ctrl.chartOptions);
        ctrl.renderSearchResult(null, null, true);
    });
};

fastSearchController.Controller.prototype.bindDropddownEventListeners = function(){
    var ctrl = this;

    $('[data-fs-underlying-name]').on('click', function(){
        ctrl.selectUnderlyingName($(this).data('fs-underlying-name'));
        $(this).closest('.dropdown-menu').hide();
    });

    $('[data-search-dropdown] [data-toggle="collapse"]').on(
        "click", ctrl.onClickSubMenuHeader
    );
};

fastSearchController.Controller.prototype.onClickSubMenuHeader = function (event) {
    event.stopPropagation();
    event.preventDefault();

    var jThis = jQuery(this);
    var jDropdown = jThis.closest(".dropdown");
    jDropdown.addClass("open");

    var collapseId = jThis.attr("href");
    jQuery(collapseId).collapse("toggle");
};

/**
 * Opens the list page with the given parameter and the selected family types reflected by the columns
 */
fastSearchController.Controller.prototype.openListPage = function(productGroup){
    var ctrl = this;
    var urlParams = "?PRODUCTGROUPNAMEDE=" + productGroup + "&pageSize=25&oldPageSize=25&pageNumber=0"
                    + "&OPTIONSENSEOPENEND=" + ctrl.optionsensopendend + "&UNDERLYINGNAME" + ctrl.language.toUpperCase()+"="+ctrl.underlyingName;

    ctrl.familytypes.forEach(function(familytype){
        urlParams += "&FAMILY" + ctrl.language.toUpperCase() + "=" + encodeURI(familytype);
    });

    window.open(ctrl.pfPageUrl + urlParams, '_self');
};

fastSearchController.Controller.prototype.openDetailPage = function(productId){
    var ctrl = this;
    window.open(ctrl.baseUrl + ctrl.detailPageUrl + productId, '_blank');
};

//select the first column on the list - needed on initialization after filling matrix with data
fastSearchController.Controller.prototype.selectColumn = function(value){
    var ctrl = this;
    var $column;

    if (value){
        $column = ctrl.jActiveTab.find('[data-fs-toggle-column][data-name="' + value + '"]')[0];
    } else {
        $column = ctrl.jActiveTab.find('[data-fs-toggle-column]')[0];
    }

    if($column){
        $column.click();
    }
};

/**
 * option sense open end has changed, render new search result
 */
fastSearchController.Controller.prototype.setFilter = function(filter){
    var ctrl = this;

    ctrl.jActiveTab.find('.horizontal-container__list').remove();

    if(filter === 'strike' || filter === 'leverage'){
        $('.vertical-slider-wrapper').hide();
    }

    if(filter === 'long' || filter === 'short'){
        this.optionsensopendend = filter.toUpperCase();
        ctrl.renderSearchResult(ctrl.getCurrentSliderValue(0), ctrl.getCurrentSliderValue(1), false, true);
    } else {
        ctrl.renderSearchResult(null, null, true);
    }

    ctrl.familytypes = [];
};

/**
 * Logic when column is selected or deselected
 * - when column is selected, it goes to front
 * - when column is deselected, it goes back to its position in the row
 */
fastSearchController.Controller.prototype.toggleColumn = function(element){
    var ctrl = this;
    var columnName = $(element).data('name');
    var jHorizontalList = $(element).closest('.horizontal-container__list');

    if(jHorizontalList.hasClass('horizontal-container__list--active')){
        var index = parseInt(jHorizontalList.data('fs-listindex'));
        var afterMarkedOnes = false;

        ctrl.jActiveTab.find('.horizontal-container__list').each(function(){
            if(index-1 == parseInt($(this).data('fs-listindex')) && !afterMarkedOnes){
                if($(this).hasClass('horizontal-container__list--active')){
                    afterMarkedOnes = true;
                } else {
                    $(this).after(jHorizontalList);
                }
            }
            if(index === 0 && 1 === parseInt($(this).data('fs-listindex')) && !afterMarkedOnes){
                if($(this).hasClass('horizontal-container__list--active')){
                    afterMarkedOnes = true;
                } else {
                    $(this).before(jHorizontalList);
                }
            }
        });

        if(afterMarkedOnes){
            ctrl.jActiveTab.find('.horizontal-container__list').each(function(i){
                if((index !== parseInt($(this).data('fs-listindex')) && !$(this).hasClass('horizontal-container__list--active'))){
                    $(this).before(jHorizontalList);
                    return false;
                } else if(ctrl.jActiveTab.find('.horizontal-container__list').length-1 === i){
                    $(this).after(jHorizontalList);
                }
            });
        }

        ctrl.familytypes.splice(ctrl.familytypes.indexOf(columnName), 1);

    } else {
        ctrl.jActiveTab.find('[data-fs-table-container] .mCSB_container').prepend(jHorizontalList);

        ctrl.jActiveTab.find(".horizontal-container").mCustomScrollbar('scrollTo', 0, {
            scrollInertia: 1000
        });

        if(columnName.indexOf('Turbo ') > -1){
            columnName = 'Turbo';
        }

        if(ctrl.familytypes.indexOf(columnName) < 0){
            ctrl.familytypes.push(columnName);
        }
    }

    jHorizontalList.toggleClass('horizontal-container__list--active');
    jHorizontalList.find('.horizontal-container__item:not(.horizontal-container__item--empty)')
        .toggleClass('horizontal-container__item--active horizontal-container__item--' + ctrl.highlightClass);

    ctrl.toggleListPageLink();
    ctrl.buildTooltips();
};

fastSearchController.Controller.prototype.setHighlightClass = function() {
    var ctrl = this;

    if(ctrl.jActiveTab.find('[data-fs-filter="long"]').is(':checked') || ctrl.jActiveTab.find('[data-fs-filter="call"]').is(':checked')){
        ctrl.highlightClass = 'green';
    } else {
        ctrl.highlightClass = 'red';
    }
};

fastSearchController.Controller.prototype.getCurrentSliderValue = function(sliderIndex) {
    var ctrl = this;

    if(ctrl.jActiveTab.find('[data-fs-wheellist="' + sliderIndex + '"]').hasClass('slick-initialized')){
        return ctrl.jActiveTab.find('[data-fs-wheellist="' + sliderIndex + '"] .slick-current').find('.item').attr('data-value');
    } else {
        return null;
    }
};

fastSearchController.Controller.prototype.toggleListPageLink = function(){
    var ctrl = this;
    var $listPageLink = $('[data-fs-listpage-link]');

    ctrl.familytypes.length > 0 ? $listPageLink.removeClass('cta--disabled') : $listPageLink.addClass('cta--disabled');
};

/**
 * When slider changed, get the values of the slider and render new search result
 */
fastSearchController.Controller.prototype.sliderChanged = function(){
    var ctrl = this;
    ctrl.renderSearchResult(ctrl.getCurrentSliderValue(0), ctrl.getCurrentSliderValue(1), false);
};

/**
 * When underlying name is selected, the graph and the search result is rendered new
 */
fastSearchController.Controller.prototype.selectUnderlyingName = function(underlyingName){
    var ctrl = this;

    $('#dropdownMenuLink').val(underlyingName);
    ctrl.underlyingName = underlyingName;

    $.event.trigger('underlyingChanged');

};

/**
 * Requests the graph data and render it with high charts. Renders as well the values on top of the graph.
 */
fastSearchController.Controller.prototype.renderGraph = function(){
    var ctrl = this;
    var underlyingName = ctrl.underlyingName;

    if(underlyingName){
        var requestParams = 'underlyingName=' + encodeURIComponent(underlyingName);

        $('.highcharts-loading').show().css('opacity','.5');
        $.getJSON(ctrl.baseUrl + ctrl.ajaxUrlGraph + '?' + requestParams, function(response){

            if(response.graphResult.graph) {

                ctrl.hideErrorOnGraph();
                ctrl.resetRangeSelector();
                var graphResult = response.graphResult;
                var graph = graphResult.graph;

                ctrl.renderGraphWithData(graph, graphResult);
            } else {
                ctrl.showErrorOnGraph();
            }

        })
    }
};

fastSearchController.Controller.prototype.showErrorOnGraph = function(){
    var ctrl = this;

    $('#fastsearch-graph-error').remove();

    $('.tabbed-search__values').hide();
    $('.chart-button-group').hide();
    $('#' + ctrl.chartOptions.chartContainerId).hide();
    $('#' + ctrl.chartOptions.chartContainerId).after('<p id="fastsearch-graph-error" class="tabbed-search__error-message">Keine Daten für ' + ctrl.underlyingName + ' vorhanden!</p>');
};

fastSearchController.Controller.prototype.hideErrorOnGraph = function(){
    var ctrl = this;

    if($('#fastsearch-graph-error').length > 0){

        $('#fastsearch-graph-error').remove();
        $('.tabbed-search__values').show();
        $('.chart-button-group').show();
        $('#' + ctrl.chartOptions.chartContainerId).show();
    }
};

fastSearchController.Controller.prototype.resetRangeSelector = function(){
    $('.chart-container .chart-button-group button.chart-button').removeClass('chart-button--active');
    $('.chart-container .chart-button-group button.chart-button[data-range-type="intraday"]').addClass('chart-button--active');
};

fastSearchController.Controller.prototype.renderGraphWithData = function(graph, graphResult){
    var ctrl = this;

    if(graph !== ''){
        var graphData = JSON.parse(graph);

        if (graphData !== null && graphData !== undefined){

            var dailyData = ctrl.sortAndFormatData(graphData.chartData.dailyData);
            var weekData = ctrl.sortAndFormatData(graphData.chartData.weekData);
            var intradayData = ctrl.sortAndFormatData(graphData.chartData.intradayData);

            ctrl.chartController.draw(dailyData, weekData, intradayData, graphData.chartData.firstWeekend, graphResult.digits);
        }
    }

    ctrl.renderUnderlyingNameValues(graphResult.underlyingNameSpot, graphResult.underlyingNameChange, graphResult.underlyingNameChangeAbsolut );
};

fastSearchController.Controller.prototype.sortAndFormatData = function(data){
    data.sort(function (a, b) {
        return a.date - b.date;
    });

    var formattedData = [];
    $.each(data, function(key, val) {
        if(val.underlyingValues["1"] !== undefined){
            formattedData.push([val.date, val.underlyingValues["1"]]);
        }
    });
    return formattedData;
};

fastSearchController.Controller.prototype.updateChart = function(rangeType, range){
    this.chartController.redraw(rangeType, range);
};

/**
 * Gets all parameters and makes a request to get matrix result data. Renders the wheel data and the result data as table matrix.
 */
fastSearchController.Controller.prototype.renderSearchResult = function(verticalSliderValue1, verticalSliderValue2, initSlider ,filtered){
    var ctrl = this;

    var requestParams = 'underlyingName=' + encodeURIComponent(ctrl.underlyingName);

    if(ctrl.jActiveTab.find('[data-fs-filter="leverage"]').length){
        var isHebel = ctrl.jActiveTab.find('[data-fs-filter="leverage"]').is(':checked');
        requestParams += '&isHebel=' + isHebel;
    }

    if(ctrl.jActiveTab.find('[data-fs-filter="long"]').length){
        var isLong = ctrl.jActiveTab.find('[data-fs-filter="long"]').is(':checked');
        requestParams += '&isLong=' + isLong;
    }

    if(ctrl.jActiveTab.find('[data-fs-filter="call"]').length){
        var isCall = ctrl.jActiveTab.find('[data-fs-filter="call"]').is(':checked');
        requestParams += '&isCall=' + isCall;
    }

    if(verticalSliderValue1 && verticalSliderValue2){
        requestParams += '&sliderValue=' + parseFloat((verticalSliderValue1 + verticalSliderValue2).replace(',','.'));
    } else if(verticalSliderValue1 && !verticalSliderValue2) {
        requestParams += '&sliderValue=' + parseFloat(verticalSliderValue1.replace(',','.'));
    } else {
        requestParams += '&sliderValue=';
    }

    var selectedColumnValues = [];
    var selectedColumns = ctrl.jActiveTab.find('.horizontal-container__list--active [data-fs-toggle-column]');

    if(!initSlider){
        selectedColumns.each(function(index, value){
            selectedColumnValues.push($(value).data('name'));
        });
    }

    ctrl.setHighlightClass();

    $.getJSON( ctrl.baseUrl + ctrl.ajaxUrlMatrix + '/' + ctrl.activeProductGroup + '?' + requestParams, function(response) {
        if (response.resultMatrix.slider.length && initSlider) {
            var wheelData = response.resultMatrix.slider;
            ctrl.renderWheels(wheelData);
        }

        var tableData = {
            columns: response.resultMatrix.columns,
            underlyingNameType: response.resultMatrix.underlyingNameType,
            isHebel: isHebel
        };

        ctrl.renderProductTable(tableData);

        $('[data-fs-toggle-column]').on('click', function(){
            ctrl.toggleColumn(this);
        });

        $('[data-fs-detailpage-link]').on('click', function(){
            if($(this).hasClass('horizontal-container__item--active')){
                ctrl.openDetailPage($(this).data('fs-detailpage-link'));
            }
        });

        if(!initSlider && !filtered){
            selectedColumnValues.reverse();
            selectedColumnValues.forEach(function(value, index){
                ctrl.selectColumn(value);
            });
        } else {
            ctrl.selectColumn();
        }
    });
};

/**
 * Get Values for Tabbed Search Dropdown
 */
fastSearchController.Controller.prototype.updateTabbedSearchDropdown = function(e , callback){
    var ctrl = this;

    var type = ctrl.activeProductGroup;

    if(e){
        var jPreviousTarget = jQuery(e.relatedTarget);
        jPreviousTarget.removeClass("active");
        type = e.target.href.substr(e.target.href.indexOf('fs-') + 3);
        ctrl.activeProductGroup = type;
    }

    $.getJSON(ctrl.baseUrl + '/bcs_ajax/fastsearch/underlyings/' + type, function(response){
        var names = Object.keys(response);

        var template = '';

        //parse response
        var parsedResponse = [];

        for (var k = 0; k < names.length ;k++) {
            var partial = {
                name: names[k],
                underlyings: response[names[k]]
            };

            parsedResponse.push(partial);
        }

        parsedResponse.sortOn("name");

        //sort response
        for (var l = 0; l < parsedResponse.length ;l++) {

            var temp = {};
            if(parsedResponse[l].name === 'Top Basiswerte') {
                temp = parsedResponse[l];
                /* remove one element at index l */
                parsedResponse.splice(l, 1);
                /* insert temp at index 0, removing 0 elements */
                parsedResponse.splice(0, 0, temp);
            }
        }

        var inputChangedWithFirstValue = false;

        //build html
        for (var i = 0; i < parsedResponse.length ;i++) {

            var head = '<div class="dropdown-header collapsed" role="button" data-toggle="collapse" ' +
                       'href="#collapse' + i + '" aria-expanded="false" aria-controls="collapseBasis">' + parsedResponse[i].name + '</div>' +
                       '<div class="collapse" id="collapse' + i + '" aria-expanded="false">';

            for (var j=0; j < parsedResponse[i].underlyings.length; j++) {
                var underlyingName = parsedResponse[i].underlyings[j].name;

                if (!inputChangedWithFirstValue){
                    $('#dropdownMenuLink').val(underlyingName);
                    ctrl.underlyingName = underlyingName;
                    $.event.trigger('underlyingChanged');
                    inputChangedWithFirstValue = true;
                }

                head += '<a class="dropdown-item" data-fs-underlying-name="' + underlyingName + '">' + underlyingName + '</a>';
            }

            head += '</div>';

            template += head;
        }

        $('.tabbed-search__dropdown').find('.mCSB_container').html(template);

        ctrl.bindDropddownEventListeners();
        callback();
    });
};

/**
 * Renders the values on top of the graph.
 */
fastSearchController.Controller.prototype.renderUnderlyingNameValues = function(underlyingNameSpot, underlyingNameChange, underlyingNameChangeAbsolut){
    var itemUnderlyingNameChange = $('#underlyingNameChange');
    var itemUnderlyingNameSpot = $('#underlyingNameSpot');
    var itemUnderlyingNameValue = $('#underlyingNameChangeValue');

    if(underlyingNameSpot){
        itemUnderlyingNameSpot.text(underlyingNameSpot);
    }

    if(underlyingNameChange){
        if(underlyingNameChange.indexOf('-') > -1){
            itemUnderlyingNameChange.addClass('tabbed-search__value--red');
        } else {
            itemUnderlyingNameChange.addClass('tabbed-search__value--green');
        }

        itemUnderlyingNameChange.text(underlyingNameChange + "%");
    }

    if(underlyingNameChangeAbsolut){
        if(underlyingNameChangeAbsolut.indexOf('-') === -1){
            underlyingNameChangeAbsolut = '+' + underlyingNameChangeAbsolut;
        }
        itemUnderlyingNameValue.text(underlyingNameChangeAbsolut);
    }
};

/**
 * Creates vertical sliders based on the wheellist data and default values.
 */
fastSearchController.Controller.prototype.renderWheels = function(slider){
    var ctrl = this;

    var sliderContainer = ctrl.jActiveTab.find('.vertical-slider-wrapper--multiple');

    if (slider.length === 1) {
        sliderContainer = ctrl.jActiveTab.find('.vertical-slider-wrapper--single');
        ctrl.jActiveTab.find('.horizontal-container').css('width', 'calc(100% - 40px)');
    } else {
        ctrl.jActiveTab.find('.horizontal-container').css('width', 'calc(100% - 92px)');
    }

    sliderContainer.show();
    var jVerticalSliders = sliderContainer.find('.vertical-slider');

    if(jVerticalSliders.hasClass('slick-initialized')){
        jVerticalSliders.slick('unslick');
    }

    var template = '{{#wheelList}}<div class="item {{className}}" data-value="{{.}}"><span class="number">{{.}}</span></div>{{/wheelList}}';
    Mustache.parse(template);   // optional, speeds up future uses

    for( var i = 0; i < jVerticalSliders.length; i++ ) {

        if (slider[i]) {
            var sliderValuesLength = slider[i].sliderValues.length;

            if(sliderValuesLength <= 7 && sliderValuesLength !== 1 ){
                var fillCount = 0;

                if(sliderValuesLength === 2 || sliderValuesLength === 3){
                    fillCount = 6;
                } else {
                    fillCount = sliderValuesLength;
                }

                for(var j = 0; j <= fillCount - 1; j++){
                    slider[i].sliderValues.push(slider[i].sliderValues[(j % sliderValuesLength)]);
                }
            }

            var rendered = Mustache.render(template, {wheelList: slider[i].sliderValues});
            sliderContainer.find('[data-fs-wheellist="' + i + '"]').html(rendered);

            var jValueInput= sliderContainer.find('[data-vertical-slider-value="' + i + '"]');

            if((i===0 && slider[0].defaultValue !== null) || (i===1 && slider[1].defaultValue !== null)){
                new sg.VerticalSlider({
                    id: i,
                    jSlider: jVerticalSliders.eq(i),
                    jValueInput: jValueInput,
                    items: slider[i].sliderValues.length
                });

                jValueInput.attr('value',slider[i].defaultValue);
                jValueInput.val(slider[i].defaultValue);

                var item = jVerticalSliders.eq(i).find('[data-value="' + slider[i].defaultValue + '"]');
                var slideItem = item.parent().parent();
                var index;

                if(slideItem.length > 1){
                    index = slideItem[1].getAttribute('data-slick-index');
                } else {
                    index = slideItem.attr('data-slick-index');
                }

                jVerticalSliders.eq(i).slick('goTo', parseInt(index));
            }
        }
    }
};

/**
 * Empties the container and renders the table matrix with new products
 */
fastSearchController.Controller.prototype.renderProductTable = function(tableData){
    var ctrl = this;

    var $mcsbContainer = ctrl.jActiveTab.find('[data-fs-table-container] .mCSB_container');
    if($mcsbContainer){
        $mcsbContainer.text('');
    }

    var propertyToSort = 'strike';
    if(tableData.isHebel){
        propertyToSort = 'hebel';
    }

    var columnIndex = 0;
    tableData.columns.forEach(function(column){

        if(column.products.length){
            var columnObject = column;
            columnObject.index = columnIndex;
            columnObject.isHebel = tableData.isHebel;
            columnObject.underlyingNameType = tableData.underlyingNameType;

            ctrl.renderProductColumn(columnObject);
            columnIndex += 1;
        }
    });

    ctrl.updateTableContainerScrollbar();

    ctrl.buildCheckableLists();
};

/**
 * Render one product column
 */
fastSearchController.Controller.prototype.renderProductColumn = function(column) {

    var ctrl = this;

    var $container = ctrl.jActiveTab.find('[data-fs-table-container] .mCSB_container');
    var $containerList = $('<div class="horizontal-container__list" data-fs-listindex="' + column.index + '"></div>');

    var idListName = column.columnName.toLowerCase().replace(/ /g,'').replace('(', '').replace(')', '').replace(':','-');

    $containerList.attr('id', idListName + 'list');
    $containerList.append(ctrl.buildProductColumnHead(column.columnName, column.description));

    column.products.forEach(function(product, index){
        if (product && product !== undefined && product !== null) {
            $containerList.append(ctrl.buildProductCell(product, column.isHebel, column.typeOfUnderlyingName));
        } else {
            $containerList.append(ctrl.buildEmptyProductCell());
        }
    });

    $container.append($containerList);
};

/**
 * Renders an empty cell
 */
fastSearchController.Controller.prototype.buildEmptyProductCell = function(){
    return $('<div class="horizontal-container__item horizontal-container__item--empty"></div>');
};

fastSearchController.Controller.prototype.formatToLocaleString = function(value, localeString, localeOptions){
    if(value){
        return value.toLocaleString(localeString, localeOptions);
    } else {
        return "";
    }
};

/**
 * Renders one product as a cell in the column.
 */
fastSearchController.Controller.prototype.buildProductCell = function(product, isHebel, typeOfUnderlyingName){
    var ctrl = this;
    var mobileClass = '';
    if(ENVIRONMENT.device.isMobile) {mobileClass = 'horizontal-container__item--no-select'}

    var listItem = $('<div class="horizontal-container__item ' + mobileClass + '" data-fs-detailpage-link="' + product.isin + '"></div>');

    var localeString = undefined;
    ctrl.language === "de" ? localeString = "de-DE" : localeString = "en-GB";

    var localeOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 2
    };

    var askValue = ctrl.formatToLocaleString(product.ask, localeString, localeOptions);
    var bidValue = ctrl.formatToLocaleString(product.bid, localeString, localeOptions);
    var askQtyValue = ctrl.formatToLocaleString(product.askQty, localeString, localeOptions);
    var bidQtyValue = ctrl.formatToLocaleString(product.bidQty, localeString, localeOptions);
    var strikeValue = ctrl.formatToLocaleString(product.strike, localeString, localeOptions);

    var stoplossBarrierValue = '';
    var stopplossTableRow = '';

    if(product.stoplossBarrier && product.stoplossBarrier !== ''){
        stoplossBarrierValue = ctrl.formatToLocaleString(product.stoplossBarrier, localeString, localeOptions);
        stopplossTableRow = '<tr><td>Stopp-Loss Barriere:</td><td>'+ stoplossBarrierValue +'</td></tr>';
    }

    var hebelValue = '';
    var hebelTableRow = '';

    if(product.hebel && product.hebel !== ''){
        var hebelValue = Math.round(product.hebel);
        hebelValue = hebelValue.toLocaleString(localeString, {minimumFractionDigits: 0, maximumFractionDigits: 0});
        hebelTableRow = '<tr><td>Hebel:</td><td>'+ hebelValue +'</td></tr>';
    }

    var abstandZurBarriereValue = '';
    var abstandZurBarriereTableRow = '';

    if(product.abstandZurBarriere && product.abstandZurBarriere !== ''){
        abstandZurBarriereValue = ctrl.formatToLocaleString(product.abstandZurBarriere, localeString, localeOptions);
        abstandZurBarriereTableRow = '<tr><td>Abstand in %:</td><td>'+ abstandZurBarriereValue +'</td></tr>';
    }

    var durationTableRow = '';
    if(product.overviewFinalDeterminationDate != null){
        var label = 'Laufzeit';
        var date = new Date(product.overviewFinalDeterminationDate).toLocaleDateString('de-DE');
        if(ctrl.language === 'en'){
            label = 'Duration';
            date = new Date(product.overviewFinalDeterminationDate).toLocaleDateString('en-GB');
        }
        durationTableRow = '<tr>' +
                   '<td>' + label + ':</td>' +
                   '<td>' + date + '</td>' +
                   '</tr>';
    }

    listItem.attr('data-title', '<div class="product-tooltip">'+
                                '<div class="product-tooltip__headline product-tooltip__headline--green">'+
                                product.familyType + ' ' + product.type +
                                '</div>'+
                                '<div class="product-tooltip__headline product-tooltip__headline--black">'+
                                product.underlyingName +
                                '</div>'+
                                '<table class="product-tooltip__table">'+
                                '<tbody>'+
                                '<tr>'+
                                '<td>Geld / Brief:</td>'+
                                '<td>'+ bidValue +' / '+ askValue +'</td>'+
                                '</tr>'+
                                '<tr>'+
                                '<td>Handelbare Stücke:</td>'+
                                '<td>'+ bidQtyValue +' / '+ askQtyValue+'</td>'+
                                '</tr>'+
                                hebelTableRow +
                                '<tr>'+
                                '<td>Basispreis:</td>'+
                                '<td>'+ strikeValue +'</td>'+
                                '</tr>'+
                                stopplossTableRow +
                                abstandZurBarriereTableRow +
                                durationTableRow +
                                '</tbody>'+
                                '</table>'+
                                '</div>');
    var textLeft = $('<span class="left"></span>');

    var innerText = '';
    if(isHebel) {
        innerText = 'Hebel: ' + hebelValue;
    } else {
        var strike = product.strike;
        var maxFracDigits = 0;
        if (strike < 500){
            maxFracDigits = 2
        }

        var value = ctrl.formatToLocaleString(strike, localeString, {minimumFractionDigits: 0, maximumFractionDigits: maxFracDigits});
        innerText = 'Basispreis: ' + value;
    }

    textLeft.text(innerText);
    listItem.append(textLeft);

    var textRight = $('<span class="right"></span>');
    textRight.text(product.wkn);
    listItem.append(textRight);

    return listItem;
};

/**
 * Renders the header for one product column
 */
fastSearchController.Controller.prototype.buildProductColumnHead = function(listName, description){
    var containerHead = $('<div class="horizontal-container__head"></div>');
    var idListName = listName.toLowerCase().replace(/ /g,'').replace('(', '').replace(')', '').replace(':', '-');

    var columnHead = '<div class="horizontal-container__checkbox">';
    if (listName) {
        columnHead += '<input data-fs-toggle-column data-name="' + listName + '" type="checkbox" id="' + idListName + '" class="toggle-list"/>' +
            '<label for="' + idListName + '"><span></span>' + listName + '</label>';
    }
    columnHead += '</div>';
    containerHead.append($(columnHead));

    if (description) {
        var info = $('<span class="horizontal-container__info" data-tooltipid="info' + idListName + '"></span>');

        var infoTooltip = $('<div class="tooltips-container">' +
                                '<div class="info-tooltip" id="info' + idListName + '">' +
                                    '<div class="info-tooltip__headline">' + listName + '</div>' +
                                    '<div class="info-tooltip__text">' + description + '</div>' +
                                '</div>' +
                            '</div>');

        containerHead.append(info);
        containerHead.append(infoTooltip);
    }

    return containerHead;
};

/**
 * Builds the container enhanced with a mCustomeScrollbar around the columns
 */
fastSearchController.Controller.prototype.initTableContainerScrollbar = function(){
    var ctrl = this;

    ctrl.jActiveTab.find("[data-fs-table-container]").mCustomScrollbar({
        axis: "x",
        scrollInertia: 0,
        mouseWheel: {
            enable: true,
            axis: "y"
        },
        updateOnContentResize: true,
        horizontalScroll:true,
        advanced:{
            autoExpandHorizontalScroll:true
        },
        callbacks: {
            onScrollStart: function () {
                var instances = jQuery.tooltipster.instances();

                jQuery.each(instances, function (i, instance) {
                    instance.close();
                });
            },
            onScroll: function () {
                var jThis = jQuery(this);

                if (jThis.data("mCS").trigger === "external") {}
            }
        }
    });
};

fastSearchController.Controller.prototype.updateTableContainerScrollbar = function(){
    var ctrl = this;

    ctrl.jActiveTab.find("[data-fs-table-container]").mCustomScrollbar('update');
};

/**
 * Initializes all tooltips
 */
fastSearchController.Controller.prototype.buildTooltips = function(){
    var ctrl = this;
    if (!ENVIRONMENT.device.isMobile) {
        ctrl.jActiveTab.find('.horizontal-container__item--active').tooltipster({
            contentAsHTML: true,
            functionBefore: function (instance, helper) {
                var jOrigin = jQuery(helper.origin);
                var content = jOrigin.data("title");

                instance.content(content);
            },
            side: "left",
            theme: "info",
            debug: false
        });
    } else {
        ctrl.jActiveTab.find('.horizontal-container__item--active').tooltipster({
            contentAsHTML: true,
            functionBefore: function (instance, helper) {
                var jOrigin = jQuery(helper.origin);
                var content = jOrigin.data("title");
                instance.content(content);
            },
            side: "left",
            trigger: 'custom',
            triggerOpen: {
                mouseenter: true,
                touchstart: true
            },
            triggerClose: {
                click: true,
                scroll: true,
                tap: true
            },
            theme: "info",
            debug: false
        });
    }

    if (!ENVIRONMENT.device.isMobile && ctrl.jActiveTab.find('.horizontal-container__info').length) {
        jQuery('.horizontal-container__info').tooltipster({
            contentAsHTML: true,
            contentCloning: true,
            functionBefore: function (instance, helper) {
                var jOrigin = jQuery(helper.origin);
                var contentId = jOrigin.data("tooltipid");
                var jContent = jQuery("#" + contentId);

                instance.content(jContent);
            },
            side: ['left', 'right', 'bottom'],
            theme: "info",
            debug: false
        });
    } else if(ctrl.jActiveTab.find('.horizontal-container__info').length) {
        jQuery('.horizontal-container__info').tooltipster({
            contentAsHTML: true,
            contentCloning: true,
            functionBefore: function (instance, helper) {
                var jOrigin = jQuery(helper.origin);
                var contentId = jOrigin.data("tooltipid");
                var jContent = jQuery("#" + contentId);

                instance.content(jContent);
            },
            side: ['left', 'right', 'bottom'],
            trigger: 'custom',
            triggerOpen: {
                mouseenter: true,
                touchstart: true
            },
            triggerClose: {
                click: true,
                scroll: true,
                tap: true
            },
            theme: "info",
            debug: false
        });
    }
};

/**
 * Creates checkable lists from the product columns
 */
fastSearchController.Controller.prototype.buildCheckableLists = function(){
    var ctrl = this;

    var jLists = ctrl.jActiveTab.find('.horizontal-container__list');

    for(var j = 0; j < jLists.length; j++) {
        new sg.CheckableList({
            jList: jLists.eq(j)
        });
    }
};
