jQuery(document).ready(function() {
    (function ($) {
        if (!!$.cookie('DISCLAIMERACCEPTED')){

        } else {
            new DisclaimerController();
        }
    })(jQuery);
});

function DisclaimerController(){
    var that = this;
    this.$body = $('body');

    this.init = function(){
        this.$container = $('.js-disclaimer');
        this.checked = false;

        this.$container.find('.disclaimer__expand').on('click',function(){
            that.$container.find('.disclaimer__dialog').addClass('disclaimer__dialog--full-text');
            that.$container.find('.disclaimer__textfield').mCustomScrollbar("destroy");
        });

        this.$container.find('.disclaimer__dialog-close').on('click',function(){
            that.$container.find('.disclaimer__dialog--full-text').removeClass('disclaimer__dialog--full-text');
            that.$container.find('.disclaimer__textfield').mCustomScrollbar({theme: "ysroll",
                scrollInertia: 0,
                mouseWheel: {
                enable: true,
                    axis: "y"
            }});
        });

        this.$container.find('.disclaimer__checkbox input:checkbox').on('change',function(){
            that.checked = this.checked;
        });

        this.$container.find('.disclaimer__submit').on('click',function(){
            that.setDisclaimerCookie();
        });

        this.showDisclaimer();
    };

    this.showDisclaimer = function(){
        this.$container.show();
        this.$body.addClass('no-scroll');
    };

    this.hideDisclaimer = function(){
        that.$container.animate({ opacity: 0 }, 250, function() {
            $(this).remove();
            that.$body.removeClass('no-scroll');
        });
    };

    this.setDisclaimerCookie = function(){
        that.checked ? $.cookie("DISCLAIMERACCEPTED", 1, {expires: 365}) : $.cookie("DISCLAIMERACCEPTED", 1);
        this.hideDisclaimer();
    };

    this.init();
}