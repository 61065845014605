$(function() {
    var validationMessages = {
        de: {
            required: 'Dieses Feld ist erfolderlich.',
            email: 'Geben Sie Ihre E-Mail Adresse an.',
            type: 'Wählen Sie mindestens einen Newslettertyp.',
            terms: 'Bestätigen Sie die Hinweise.'
        },
        en: {
            required: 'This field is required.',
            email: 'Please enter a valid email address.',
            type: 'Please select at least one newsletter type.',
            terms: 'Please accept the terms.'
        }
    };

    $("form[id='abonnementOrderForm']").validate({
        ignore: [],
        errorPlacement: function (error, element) {
            if (element.attr("name") == "disclaimer" || element.attr("name") == "abonnements") {
                error.appendTo("#form-error");
            } else {
                error.insertAfter(element);
            }
        },
        rules: {
            "abonnements": {
                required: true,
                minlength: 1
            },
            firstname: "required",
            lastname: "required",
            email: {
                required: true,
                email: true
            },
            disclaimer: {
                required: true
            }
        },
        messages: {
            firstname: validationMessages[ENVIRONMENT.language.lowercase].required,
            lastname: validationMessages[ENVIRONMENT.language.lowercase].required,
            email: validationMessages[ENVIRONMENT.language.lowercase].email,
            disclaimer: {
                required: validationMessages[ENVIRONMENT.language.lowercase].terms
            },
            "abonnements": validationMessages[ENVIRONMENT.language.lowercase].type
        },
        submitHandler: function(form) {
            form.submit();
        }
    });
});