function objectEval(text) {
    text = text.replace(/\n/g, ' ');
    text = text.replace(/\r/g, ' ');
    if (text.match(/^\s*\{.*\}\s*$/)) {text = '[' + text + '][0]';}
    return eval(text);
}

var initPushService = function() {
    if(dwr.engine._scriptSessionId == null) {
        setTimeout("initPushService()", 2000);
    } else {
        dwr.engine.setActiveReverseAjax(true);
        dwr.engine.setNotifyServerOnPageUnload(true);
        dwr.engine.setErrorHandler(doNothingHandler);
        dwr.engine.setWarningHandler(doNothingHandler);
    }
};

function doNothingHandler(msg, exc) {
    // be silent
}

var reply0 = function(data) {};

//Listpage
var updateUnderlyinSpotData = function(jsonData){
    var updateAttributes = eval("("+jsonData+")");
    if(updateAttributes.data != null){
        var data = updateAttributes.data;
        updateUnderlyingSpot(data.underlyingSpot, language);
        updateUnderlyingSpotChange(data.underlyingSpotChangeDate, language);
        blinkUnderlyingSpot(data.underlyingSpotTrend, $('.list-table__indexnumber'));
        updateChangeTime($('.list-table__indexdate'),data.underlyingSpotChangeDate, language)
    }
}

//Detailpage
var updateUnderlyinSpotDataDetailPage = function(jsonData){
    var updateAttributes = eval("("+jsonData+")");
    if(updateAttributes.data != null){
        var data = updateAttributes.data;

        /* Update Left */
        updateUnderlyingSpotDetail(data.underlyingSpot, language);
        updateChangeDetail($('#detail-underlyingChange'), data.underlyingChangePercent, language, data.underlyingChangePercentTrend);
        updateTimeStamp('detail-underlyingSpot', data.underlyingSpotChangeDate, language);
        blinkDetail(data.underlyingChangePercentTrend, $('#detail-underlyingSpot'))

        /* Update Middle */
        updateCourseValue($("#detail-bid"), data.bid, language);
        updateCourseValue($("#detail-ask"), data.ask, language);
        blinkDetail(data.bidPriceTrend, $("#detail-bid"))
        blinkDetail(data.askPriceTrend, $("#detail-ask"))
        updateTimeStamp('detail-limittime', data.timestamp, language);

        /* Update Right */
        updateChangeDetail($('#detail-changePercent'), data.changePercent, language, data.changePercentTrend);
    }
};

var updateUnderlyingSpotDetail = function(value, language){
    var formattedCurrencyValue = formatValueWithFormatter(value, "CURRENCY", language);
    $('#detail-underlyingSpot').html(formattedCurrencyValue);
};

var updateChangeDetail = function(element, value, language, trend){
    var formattedCurrencyValue = formatValueWithFormatter(value, "PERCENT", language);
    var prefix = "";
    if (trend.indexOf("UP")>-1) {
        element.removeClass('detail-reference__number--negative');
        element.addClass('detail-reference__number--positive');
        prefix = "+";
    }
    if(trend.indexOf("DOWN")>-1){
        element.addClass('detail-reference__number--negative');
        element.removeClass('detail-reference__number--positive');
    }
    if(trend.indexOf("NO_CHANGE")>-1){
        element.html('<span class="value tendency NO_CHANGE">-</span>')
    }
    element.html(prefix + formattedCurrencyValue);
};

function blinkDetail(trend, element){
    if (trend.indexOf("UP")>-1) {
        element.attr('class','detail-reference__number pill pill--green');
        //element.effect("highlight", {color: '#36A916'}, 1600);
    }
    if (trend.indexOf("DOWN")>-1) {
        element.attr('class','detail-reference__number pill pill--red');
        //element.effect("highlight", {color: '#E60000'}, 1600);
    }
    setTimeout(function(){
        element.attr('class','detail-reference__number pill');
    },3000)
}

var updateUnderlyingSpotChange = function(value, language){
    var date = new Date(value).toLocaleDateString('de-DE');
    if(language==="EN"){
        date = new Date(value).toLocaleDateString('en-GB');
    }
    $('detail_bid').html(date);
};

var updateChangeTime = function($element, value, language){
    var date = new Date(value);
    var dateTimeString = date.toLocaleDateString('de-DE') + ' ' + date.toLocaleTimeString('de-DE');
    if(language==="EN"){
        dateTimeString = date.toLocaleDateString('en-GB') + ' ' + date.toLocaleTimeString('en-GB');
    }
    $element.html(dateTimeString);
};

var updateUnderlyingSpot = function(value, language){
    var underlyingSpotContainer = $('.list-table__indexnumber');
    var digits = underlyingSpotContainer.data('digits') || 2;
    var formattedCurrencyValue = formatValueWithFormatter(value, "CUSTOM", language, digits);
    underlyingSpotContainer.find('.underlyingspot').html(formattedCurrencyValue);
};

function blinkUnderlyingSpot(trend, element){
    if (trend.indexOf("UP")>-1) {
        element.attr('class','list-table__indexnumber list-table__indexnumber--up');
        //element.effect("highlight", {color: '#36A916'}, 1600);
    }
    if (trend.indexOf("DOWN")>-1) {
        element.attr('class','list-table__indexnumber list-table__indexnumber--down');
        //element.effect("highlight", {color: '#E60000'}, 1600);
    }
    setTimeout(function(){
        element.attr('class','list-table__indexnumber');
    },3000)
}

//Navigation Market Overview
var updateData = function(jsonData) {
    var updateAttributes = eval("("+jsonData+")");
    if(updateAttributes.data != null){
        var data = updateAttributes.data;

        /*Probably never used*/
        updateCourseValue($("#"+updateAttributes.id+"_bid"), data.bid, language);
        blink(data.bidPriceTrend, $("#"+updateAttributes.id+"_bidPriceTrend"));
        blink("tendenz"+data.bidPriceTrend, $("#tendenz"+updateAttributes.id+"_bidPriceTrend"));
        updateCourseValue($("#"+updateAttributes.id+"_ask"), data.ask, language);
        blink(data.askPriceTrend, $("#"+updateAttributes.id+"_askPriceTrend"));
        blink("tendenz"+data.askPriceTrend, $("#tendenz"+updateAttributes.id+"_askPriceTrend"));

        updateUnderlyingSpotValue(updateAttributes.id, data.underlyingSpot, language);
        blink(data.underlyingSpotTrend, $("#"+updateAttributes.id+"_underlyingSpotTrend"));
        blink("tendenz"+data.underlyingSpotTrend, $("#"+updateAttributes.id+"_underlyingSpotTrend"));
        updateUnderlyingChangePercent(updateAttributes.id, data.underlyingChangePercent, data.underlyingChangePercentTrend, language);
        updateTimeStamp(updateAttributes.id, data.timestamp, language)
    }
};

var updateUnderlyingSpotValue = function(id, value, language) {
    if (is_numeric(value)) {

        var underlyingSpotContainer = $("#"+id+"_underlyingSpot");
        var digits = underlyingSpotContainer.data('digits');

        var formattedValue = formatValueWithFormatter(value, "CUSTOM", language, digits);
        underlyingSpotContainer.html(formattedValue);
        var formattedCurrencyValue = formatValueWithFormatter(value, "CURRENCY", language);
        $("#"+id+"_underlyingSpotCurrency").html(formattedCurrencyValue);

    }
};


var updateCourseValue = function(element, value, language) {
    if (is_numeric(value)) {
        var formattedValue = formatValueWithFormatter(value, "COURSE", language);
        element.html(formattedValue);
    }
};


var updateUnderlyingChangePercent = function(id, changePercent, changePercentTrend, language) {
    if (is_numeric(changePercent)) {
        var formattedChangePercent = formatValueWithFormatter(changePercent, "PERCENT", language);
        if (changePercent >= 0) {
            formattedChangePercent = "+" + formattedChangePercent;
        }
        var item = $("#"+id+"_changePercent");
        item.html(formattedChangePercent);
        blink(changePercentTrend, item);
        //item.attr('class', changePercentTrend);
    }
};

var updateTimeStamp = function(id, timeStamp, language) {
    if (is_numeric(timeStamp)) {
        var formattedTimeStamp = formatValueWithFormatter(timeStamp, "DATETIME", language);
        $("#"+id+"_timeStamp").html(formattedTimeStamp);
    }
};

function blink(trend, element){
    if (trend.indexOf("UP")>-1) {
        element.attr('class','markets__value-indicator pill pill--green');
        //element.effect("highlight", {color: '#36A916'}, 1600);
    }
    if (trend.indexOf("DOWN")>-1) {
        element.attr('class','markets__value-indicator pill pill--red');
        //element.effect("highlight", {color: '#E60000'}, 1600);
    }
    setTimeout(function(){
        element.attr('class','markets__value-indicator pill');
    },3000)
}

var unsubscribeAll = function() {pushSubscriptionController.unsubscribeAll();};

function formatValueWithFormatter(value, formatter, language, digits){
    if (formatter == 'COURSE') {
        var fractionDigitsCount;
        if (value < 0.1) {
            fractionDigitsCount = 3;
        } else {
            fractionDigitsCount = 2;
        }
        if (language == "EN") {
            value=formatNumber(",",".",value, fractionDigitsCount);
        }else {
            value=formatNumber(".",",",value, fractionDigitsCount);
        }
    }
    if (formatter == 'NUMBER') {
        if (language == "EN") {
            value=formatNumber(",",".",value, 2);
        }else {
            value=formatNumber(".",",",value, 2);
        }
    }
    if (formatter == 'PERCENT'){
        value = value * 100;
        if (language == "EN"){
            value=formatNumber(",",".",value, 2);
        }else {
            value=formatNumber(".",",",value, 2);
        }
        value=value+"%";
    }
    if (formatter == 'DATE'){
        var date= new Date(value);
        if (language == "EN"){
            value=zeroPad(date.getMonth()+1,2)+"/"+zeroPad(date.getDate(),2)+"/"+date.getFullYear();
        }else {
            value=zeroPad(date.getDate(),2)+"."+zeroPad(date.getMonth()+1,2)+"."+date.getFullYear();
        }
    }
    if (formatter == 'DATETIME'){
        var date= new Date(value);
        if (language == "EN"){
            value= date.getDate() + "/" + date.getMonth() + "/" + (date.getFullYear() - 2000);
            value += " " + zeroPad(date.getHours(),2) + ":" + zeroPad(date.getMinutes(),2) + ":" + zeroPad(date.getSeconds(),2);
            /* append PM or AM */
            value += (date.getHours() > 11)?' PM':' AM';
        }else {
            value=zeroPad(date.getDate(),2) + "." + zeroPad(date.getMonth()+1,2) + "." + (date.getFullYear() - 2000);
            value += " " + zeroPad(date.getHours(),2) + ":" + zeroPad(date.getMinutes(),2) + ":" + zeroPad(date.getSeconds(),2);
        }
    }
    if (formatter == 'BOOLEAN'){
        if (value){
            if (language == "EN"){
                value='Yes';
            }else{
                value='Ja';
            }
        } else {
            if (language == "EN"){
                value='No';
            }else{
                value='Nein';
            }
        }
    }
    if (formatter == 'CURRENCY') {
        if (language == "EN") {
            value=formatNumber(",",".",value, 2);
        }else {
            value=formatNumber(".",",",value, 2);
        }
    }
    if (formatter == 'CUSTOM' && digits!==undefined && !isNaN(digits)) {
        if (language == "EN") {
            value=formatNumber(",",".",value, digits);
        }else {
            value=formatNumber(".",",",value, digits);
        }
    }
    return value;
}

function formatNumber(thousandDelimeter, comma, number, fractionDigitsCount) {
    roundFactor = Math.pow(10, fractionDigitsCount);
    number=Math.round(number*roundFactor)/roundFactor;
    number =String(number);
    var splitted = number.split(".");
    var intPart = addThousandDelimeter(splitted[0], thousandDelimeter);
    var afterComma=zeroPadFractionDigits(splitted[1], fractionDigitsCount);
    return intPart+comma+afterComma;
}

function addThousandDelimeter(number, delimeter){
    var counter = 0;
    var formatted = '';
    var separator = '';
    number = number.toString();
    var offset = number.charAt(0) == '-' ? 1 : 0;

    for(var i = number.length - 1; i >= offset; i--) {
        separator = (counter > 0 && counter % 3 === 0) ? delimeter : '';
        formatted = number.charAt(i) + separator + formatted;
        counter++;
    }
    if (offset===1){
        return '-'+formatted;
    }
    return formatted;
}

function zeroPad(num,count){
    if(num===undefined){
        num=0;
    }
    var numZeropad = String(num);
    while(numZeropad.length < count) {
        numZeropad = "0" + numZeropad;
    }
    return numZeropad;
}

function zeroPadFractionDigits(num,count){
    if(num===undefined){
        num=0;
    }
    var numZeropad = String(num);
    while(numZeropad.length < count) {
        numZeropad = numZeropad + "0";
    }
    return numZeropad;
}

function is_numeric(input){
    return is_numeric_by_type(input) || is_numeric_string(input);
}
function is_numeric_by_type(input){
    return typeof(input)=='number';
}
function is_numeric_string(input){
    return !isNaN(input);
}
