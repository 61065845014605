var sg = sg || {};
sg.NewsletterCheckbox = sg.NewsletterCheckbox || (function (jQuery)
{
    var NewsletterCheckbox = function (initValues)
    {
        var jCheckBoxesWrapper = jQuery(".newsletter-subscriptions__checkable");
        var jCheckBoxesInput = jCheckBoxesWrapper.find("input[type=checkbox]");
        var jCheckList = jQuery(".newsletter-subscriptions__checkable-indicator");
        var jCheckListInput = jCheckList.find("input[type=checkbox]");
        
        jQuery.extend(true, this,
        {
            jCheckBoxesWrapper: jCheckBoxesWrapper,
            jCheckBoxesInput: jCheckBoxesInput,
            jCheckList: jCheckList,
            jCheckListInput: jCheckListInput
        });
        
        attachEvents.call(this);
    };
    
    var syncBoxes = function syncBoxes(jItem)
    {
        var jThis = jItem;
        var prop = jThis.prop("checked");
        var sync = jThis.data("sync");
        var jSync = jQuery("#" + sync);
        
        jSync.prop("checked", prop);
    };
    
    var attachEvents = function attachEvents()
    {
        var that = this;
        
        that.jCheckBoxesInput.on("change", function (event)
        {
            event.preventDefault();
            
            syncBoxes.call(that, jQuery(this));
        });
        
        that.jCheckListInput.on("change", function (event)
        {
            event.preventDefault();
            
            syncBoxes.call(that, jQuery(this));
        });
    };
    
    return NewsletterCheckbox;
}(jQuery));