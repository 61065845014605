var sg = sg || {};

sg.ChartFormatController = sg.ChartFormatController || (function (jQuery) {

    var chartFormats = {
        de: {
            date: '%d.%m.%Y',
            shortDate: '%d.%m.',
            time: '%H:%M',
            full: '%d.%m. %H:%M',
            decimalPoint: ',',
            thousandsStep: '.'
        },
        en: {
            date: '%Y/%m/%d',
            shortDate: '%m/%d',
            time: '%I:%M %p',
            full: '%m/%d %I:%M %p',
            decimalPoint: '.',
            thousandsStep: ','
        },
        default: {
            date: '%d.%m.%Y',
            shortDate: '%d.%m',
            time: '%H:%M',
            full: '%d.%m. %H:%M',
            decimalPoint: ',',
            thousandsStep: '.'
        }
    };

    var init = function (lang) {
        if (lang) {
            this.format = chartFormats[lang] || sg.DetailChart.chartFormats.default;
        } else {
            this.format = chartFormats.default;
        }
    };

    return init;

}(jQuery));

sg.ChartFormatController.prototype.formatTooltip = function(chart, chartRange, decimals, displaySeriesName){

    var format = this.format;
    var xFormat = (chartRange==="intraday" || chartRange==="1 W") ? format.full : format.date;

    var tooltip = [];
    var x = Highcharts.dateFormat(xFormat, new Date(chart.x));
    tooltip.push('<b>'+x+'</b>');
    $.each(chart.points, function(i, point) {
        var y = Highcharts.numberFormat(point.y, decimals, format.decimalPoint, format.thousandsStep);
        var tooltipString = '';
        if (displaySeriesName) {
            tooltipString += '<span style="color:'+point.color+'">\u25CF</span> '+point.series.name +': ';
        }
        tooltipString += y;
        tooltip.push(tooltipString);
    });

    return tooltip.join('<br>');
};

sg.ChartFormatController.prototype.formatXAxisLabel = function(chart, chartRange){

    var ctrl = this;

    var format;
    if (chartRange==="intraday"){
        format = this.format.time;
    } else if (ctrl.endsWith(chartRange, "M") || ctrl.endsWith(chartRange, "W")) {
        format = this.format.shortDate;
    } else { // |) 1 Y
        format = this.format.date;
    }

    return Highcharts.dateFormat(format, chart.value);
};

sg.ChartFormatController.prototype.formatYAxisLabel = function(chart, decimals){
    var format = this.format;
    return Highcharts.numberFormat(chart.value, decimals, format.decimalPoint, format.thousandsStep);
};

sg.ChartFormatController.prototype.endsWith = function(string, suffix){
    return string.indexOf(suffix, string.length - suffix.length) !== -1;
};
