jQuery(document).ready(function() {
    (function ($) {
        var $submenu = $('.dropdown-menu .dropdown-item[data-submenu]');
        var windowWidth = window.innerWidth;

        $submenu.hover(function () {
            if (windowWidth > 1024) {
                $(this).attr('aria-expanded', 'true');
            }
        }, function () {
            if (windowWidth > 1024) {
                $(this).attr('aria-expanded', 'false');
            }
        });
    })(jQuery);
});

