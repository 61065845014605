var sg = sg || {};

sg.ChangeDomPosition = sg.ChangeDomPosition || (function ($) {
    var ChangeDomPosition = function (initValues) {
        initView.call(this);
    };

    var changeDomPositionHandler = function changeDomPositionHandler() {
        var windowWidth = viewport().width;
        var jCheckBoxes = $(".newsletter__checkboxes");
        var jSearch = $(".header__search");
        var jSubmit = $(".newsletter .newsletter__submit");
        var jProductTable = $('.productlist__table');
        var jHeader = $('header');
        var jHeaderLogo = $('.header__logo');
        var jNavbar = $("nav.navbar");
        var jMarketsWrapper = jNavbar.find(".markets-wrapper");
        var jNavbarToggler = jNavbar.find(".navbar-toggler");
        var jNavigation = jNavbar.find(".navigation__links");
        var jNewsIndicator = $(".news-indicator");
        var jDetailReferenz = $(".detail-reference");
        var jDetailReferenzMiddle = jDetailReferenz.find(".detail-reference__middle");
        var jDetailReferenzLeft = jDetailReferenz.find(".detail-reference__left");

        if (windowWidth <= 1024) {
            jProductTable.addClass("table-sm");
            jNavbarToggler.after(jHeaderLogo);
            jMarketsWrapper.before(jSearch.parent());
            jHeaderLogo.after(jNewsIndicator);
        } else {
            jProductTable.removeClass("table-sm");
            jNavigation.after(jNewsIndicator);
            jSearch.before(jHeaderLogo);
            jHeader.find(".header__row").after(jSearch.parent());
            jHeader.after(jNavbar);
        }

        if (windowWidth <= 767) {
            jSubmit.before(jCheckBoxes);
            jDetailReferenzLeft.before(jDetailReferenzMiddle);
        } else {
            jSubmit.after(jCheckBoxes);
            jDetailReferenzLeft.after(jDetailReferenzMiddle);
        }
    };

    var initView = function initView() {
        //-----> inital check elements dom positon
        changeDomPositionHandler();

        //-----> check elements dom positon on resize
        sg.Resize.addFunction(changeDomPositionHandler);
    };

    return ChangeDomPosition;
}(jQuery));