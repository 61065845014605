// Avoid `console` errors
(function () {
    var method;
    var noop = function (){};
    var methods = [
        'assert', 'clear', 'count', 'debug', 'dir', 'dirxml', 'error',
        'exception', 'group', 'groupCollapsed', 'groupEnd', 'info', 'log',
        'markTimeline', 'profile', 'profileEnd', 'table', 'time', 'timeEnd',
        'timeline', 'timelineEnd', 'timeStamp', 'trace', 'warn'
    ];
    var length = methods.length;
    var console = (window.console = window.console || {});

    while (length--) {
        method = methods[length];

        // Only stub undefined methods.
        if (!console[method]) {
            console[method] = noop;
        }
    }
}());

/*
 * this function to use instead of $(window).width(), because of excluded scrollbars in several browsers like chrome
 * get width with viewport().width, get height with viewport().height
 */

function viewport() {
    var e = window, a = 'inner';
    if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }

    return { width : e[ a + 'Width' ] , height : e[ a + 'Height' ] };
}

jQuery(document).ready(function() {
    var $leverElements = jQuery('.hebel').find('.number');
    var $basisElements = jQuery('.basis').find('.number');
    var $elementsToTruncate = $leverElements.add($basisElements);

    $elementsToTruncate.each(function(index) {
        var $toTruncate = jQuery(this);
        var text = $toTruncate.text();

        if ((text % 1) != 0 ) {
            $toTruncate.text(text.substring(0, text.indexOf(',')));
        }
    });

    //------> elements dom position watcher
    new sg.ChangeDomPosition();
    
    //------> responsive helpers
    new sg.ResponsiveHelpers();

    //------> checkable product groups
    var jProductGroupWrappers = jQuery('.list-filter__type-product-wrapper');
    for(var j = 0; j < jProductGroupWrappers.length; j++) {
        new sg.ProductGroupList({
            jProductGroupWrapper: jProductGroupWrappers.eq(j)
        });
    }
    
    //------> init content slider
    new sg.Slider({
        jNewsSlider: jQuery('.news__slider'),
        jProductsSlider: jQuery('.popular-products'),
        jTabsSlider: jQuery('[data-slider="tabbed-search-tabs"]'),
        jMarketsListSlider: jQuery('.markets__list'),
        jMarketsTickerSlider: jQuery('.markets__ticker'),
        jFurtherNewsletterSlider: jQuery(".current-newsletter__slider--further"),
        jNewsletterSubscriptionSlider: jQuery(".newsletter-subscriptions .newsletter-subscriptions__slider"),
        jCurrentNewsletterSlider: jQuery(".current-newsletter__slider--current"),
        jWebinareCategoriesSlider: jQuery(".webinare-categories__slider")
    });

    //------> fix bootstrap tabs misbehaviour / remove active class on prev tabs
    jQuery('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
        var jPreviousTarget = jQuery(e.relatedTarget);
        jPreviousTarget.removeClass("active");
    });

    //------> global Highcharts Format
    Highcharts.setOptions({
        global: {
            useUTC: false
        }
    });

    (function (H) {
        H.wrap(H.Legend.prototype, 'positionCheckboxes', function (p, scrollOffset) {
            var alignAttr = this.group.alignAttr,
                translateY,
                clipHeight = this.clipHeight || this.legendHeight;

            if (alignAttr) {
                translateY = alignAttr.translateY;
                H.each(this.allItems, function (item) {
                    var checkbox = item.checkbox,
                        bBox = item.legendItem.bBox,
                        top;

                    if (checkbox) {
                        top = (translateY + checkbox.y + (scrollOffset || 0) + 3);
                        H.css(checkbox, {
                            left: (alignAttr.translateX + item.checkboxOffset + checkbox.x - 60) + 'px',
                            top: top + 'px',
                            display: top > translateY - 6 && top < translateY + clipHeight - 6 ? '' : 'none'
                        });
                    }
                });
            }
        });
    })(Highcharts);

    //------> refresh chart
    jQuery('#mobile-chart').on('shown.bs.collapse', function () {
        sg.FastSearchChart.chart.reflow();
        sg.FastSearchChart.chart.redraw();
    });
   
    //list page
    if(typeof Modernizr !== "undefined") {
        var datepicker = jQuery('input.datepicker');

        if (datepicker.length) {
            ENVIRONMENT.language.lowercase === "de" ? $.datepicker.setDefaults($.datepicker.regional['de']) : $.datepicker.setDefaults($.datepicker.regional['en']);

            datepicker.each(function () {
                $(this).datepicker();
            });
        }
    }
    
    if(jQuery(".list-filter__slider").length) {
        var jListFilterSliders = jQuery(".list-filter__slider");
        
        for(var lfs = 0; lfs < jListFilterSliders.length; lfs++) {
            var jListFilterSlider = jQuery(jListFilterSliders[lfs]);
            
            new sg.RangeSlider({
                jRangeSlider: jListFilterSlider
            });
        }
    }

    var jNewsletterSubscriptionToogle = jQuery(".newsletter-subscriptions__toggle");

    if(jNewsletterSubscriptionToogle.length) {
        jNewsletterSubscriptionToogle.on("click", function (event) {
            event.preventDefault();

            var jThis = jQuery(this);
            var jNewsletterSubscription = jQuery(".newsletter-subscriptions");
            var jToggleContent = jThis.parent().find(".newsletter-subscriptions__toggle-content");

            jThis.toggleClass("open");
            jNewsletterSubscription.toggleClass("closed");
            jToggleContent.stop().slideToggle();
        });
    }

    if(jQuery(".newsletter-subscriptions__checkable").length) {
        new sg.NewsletterCheckbox({});
    }

    if(jQuery(".about-documents .about-documents-subtabs").length) {
        var $dropdown = jQuery(".about-documents .about-documents-subtabs");
        var $dropdownButton = $dropdown.find("button:first-child");
        var $dropdownLink = $dropdown.find("li a");

        $dropdownLink.on("click", function (event) {
            if(window.innerWidth < 601){
                event.preventDefault();
                var $this = jQuery(this);
                var href = $this.attr("href");

                $dropdown.find("button:first-child").text($this.text()).val($this.text());
                $dropdownButton.click();
            }
        });
    }

    // navigation
    jQuery('.dropdown-item[data-submenu]').click(function(event){
        event.preventDefault();
        event.stopImmediatePropagation();

        var jThis = jQuery(this);
        var jSubmenu = jQuery("#"+jThis.data('submenu'));
        var ariaExpanded = jThis.attr("aria-expanded");

        if(ariaExpanded === "true") {
            jThis.attr("aria-expanded","false");
            jSubmenu.css('height', '0px');
        } else {
            jThis.attr("aria-expanded","true");
            jSubmenu.css('height', 'auto');
        }
    });
});
