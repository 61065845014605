jQuery(document).ready(function(){
    (function($) {
        $('[data-custom-scroll]').mCustomScrollbar({
            theme: "ysroll",
            scrollInertia: 0,
            mouseWheel: {
                enable: true,
                axis: "y"
            }
        });

        $(".subscriptions__trade-scroll").mCustomScrollbar({
            axis: "x",
            scrollInertia: 0,
            mouseWheel: {
                enable: true,
                axis: "y"
            },
            callbacks: {
                onScrollStart: function () {
                    var instances = $.tooltipster.instances();

                    $.each(instances, function (i, instance) {
                        instance.close();
                    });
                }
            }
        });
    }(jQuery));
});
