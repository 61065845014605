var advProductTeaserCtrl;

jQuery(document).ready(function(){
    (function($){

        if($('.producttable table.producttable__table').length){
            advProductTeaserCtrl = new advProductTeaser.Controller({
                limit: '15',
                resourceUrl: '/.resources/bcs-reloaded-config',
                advProductDetailsUrl: '/de/product.html?isin='
            })
        }

    })(jQuery)
});

var advProductTeaser = advProductTeaser || {};

advProductTeaser.Controller = function(options){
    this.baseUrl = ENVIRONMENT.ajax.baseUrl;

    this.offset = 0;
    this.currentPage = 1;

    this.limit = options.limit;
    this.resourceUrl = options.resourceUrl;
    this.advProductDetailsUrl = options.advProductDetailsUrl;

    this.init();
};

advProductTeaser.Controller.prototype.init = function(){
    var ctrl = this;

    var offset = ctrl.offset;
    var limit = ctrl.limit;

    ctrl.sendRequest(ctrl.baseUrl + "/bcs_ajax/advProducts", {offset: offset, limit: limit}, function (teaserModel) {
        ctrl.renderTable(teaserModel);
        ctrl.renderPagination(teaserModel);

        if(teaserModel.count < 1){
            ctrl.hideTable();
        }
    },function(){
        ctrl.hideTable();
    });
};

advProductTeaser.Controller.prototype.changePage = function(page){
    var ctrl = this;
    ctrl.currentPage = page;
    ctrl.offset = (page-1) * ctrl.limit;
    ctrl.init();
};

advProductTeaser.Controller.prototype.hideTable = function(){
    $('.producttable').hide();
};

advProductTeaser.Controller.prototype.renderPagination = function(teaserModel){
    var ctrl = this;

    var $productPagination = $('.producttable nav.producttable__pagination');
    $productPagination.html('');

    var pagesCount = Math.ceil(teaserModel.count / ctrl.limit);

    if (pagesCount > 1) {
        var pages = [];
        for (var i=1; i<=pagesCount; i++) {
            pages.push(i);
        }

        var view = {
            current: ctrl.currentPage,
            pages: pagesCount
        };

        if (pagesCount > ctrl.currentPage) {
            view.next = ctrl.currentPage + 1;
        } else {
            view.next = -1;
        }

        if (ctrl.currentPage > 1) {
            view.previous = ctrl.currentPage -1;
        } else {
            view.previous = -1;
        }

        var template = ctrl.getPaginationTemplate();

        var pagination = ctrl.renderMustacheTemplate(template, view);
        $productPagination.append(pagination);

        $productPagination.find('.paginate_button').on('click', function(event) {
            event.preventDefault();

            var page = $(this).data("page");
            if(page > -1){
                ctrl.changePage(page);
            }
        });
    }
};

advProductTeaser.Controller.prototype.renderTable = function(teaserModel){
    var ctrl = this;

    var $producttable = $('.producttable table.producttable__table');
    $producttable.find("tbody").html('');

    if(teaserModel.products){
        var mappedAdvProducts = teaserModel.products.map(function (product) {
            var displayTotalPercentage = product.totalPercentage > 0;
            var state = product.state.toLowerCase();
            if (state === "finished" || state === "") {
                state = "closed";
            }
            if (state === "limited") {
                state = "pending";
            }

            return $.extend(true, {}, product, {
                displayTotalPercentage: displayTotalPercentage,
                state: state
            });
        });

        var template = ctrl.getTableTemplate();

        var rows = ctrl.renderMustacheTemplate(template, {
            resourceUrl: ctrl.resourceUrl,
            advProductDetailsUrl: ctrl.advProductDetailsUrl,
            advProducts: mappedAdvProducts
        });

        $producttable.find("tbody").append(rows);
    }
};

advProductTeaser.Controller.prototype.renderMustacheTemplate = function(template, view){
    Mustache.parse(template);

    return Mustache.render(template, view);
};

advProductTeaser.Controller.prototype.getTableTemplate = function(){
    return '' +
        '{{#advProducts}}' +
        '<tr title="{{title}}" onclick="window.location.href={{advProductDetailsUrl}}{{isin}}">' +
            '<td><span class="producttable__status producttable__status--{{state}}"></span></td>' +
            '<td><a href="{{advProductDetailsUrl}}{{isin}}" class="bold">{{wkn}}</a></td>' +
            '<td>{{coupon}}</td>' +
            '<td>{{startDate}}-{{date}}</td>' +
            '<td>{{issuePrice}}{{#new}}<div class="adv-new"><span>Neu</span></div>{{/new}}</td>' +
            '<td>{{#displayTotalPercentage}}' +
                '<div class="adv-date-range">' +
                    '<span class="range-fill" style="width:{{totalPercentage}}%; left:{{startPercentage}}% ">' +
                    '<span class="date-start">{{startDateFormatted}}</span>' +
                    '<span class="date-end">{{endDateFormatted}}</span>' +
                    '</span>' +
                    '<span class="range-text"  style="left:{{startPercentage}}%;">{{title}}</span>' +
                    '{{/displayTotalPercentage}}{{^displayTotalPercentage}}{{title}}{{/displayTotalPercentage}}' +
                '</div>'+
            '</td>'+
            '<td nowrap>{{#pib}}'+
                '<a href="{{pib.url}}" class="producttable__prib tooltipstered" data-tooltipid="pib1">PIB</a> ' +
                '<a href="{{pib.url}}" class="producttable__arrow"></a>' +
            '{{/pib}}</td>' +
            '<td nowrap>{{#termsheet}}' +
                '<a href="{{termsheet.url}}" class="producttable__prib tooltipstered" data-tooltipid="pib1">TermSheet</a>' +
                '<a href="{{termsheet.url}}" class="producttable__arrow"></a>' +
            '{{/termsheet}}</td>' +
            '<td>' +
                '<a href="#" class="producttable__arrow-forward"></a>' +
            '</td>' +
        '</tr>' +
        '{{/advProducts}}';
};

advProductTeaser.Controller.prototype.getPaginationTemplate = function(){
    return '' +
        '<div class="list-table__pagination">' +
            '<div class="list-table__pages">' +
                '<div class="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">' +
                '{{#current}}{{current}}{{/current}} von {{#pages}}{{pages}}{{/pages}} Seiten' +
                '</div>' +
            '</div>' +
            '<div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">' +
                '{{#previous}}<a class="paginate_button previous disabled" data-page="{{previous}}" aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" id="DataTables_Table_0_previous"></a>{{/previous}}' +
                '<span>' +
                    '{{#current}}<a class="paginate_button current" data-page="{{current}}" aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0">{{current}}</a>{{/current}}' +
                '</span>' +
                '{{#next}}<a class="paginate_button next" data-page="{{next}}" aria-controls="DataTables_Table_0" data-dt-idx="3" tabindex="0" id="DataTables_Table_0_next"></a>{{/next}}' +
            '</div>' +
        '</div>';
};

advProductTeaser.Controller.prototype.sendRequest = function (url, params, callback, error) {
    $.ajax({
        url: url,
        type: 'get',
        dataType: 'json',
        data: params,
        crossDomain: true,
        success: callback,
        error: error
    });
};
