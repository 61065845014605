jQuery(document).ready(function(){

    var $detailContainer = $('#chart-container-detail');

    if($detailContainer.length){

        var rangeLabel = (ENVIRONMENT.language.lowercase === 'de' ? 'J' : 'Y');

        var detailChartController = new queo.DetailChartController();
        detailChartController.renderChartWithLimit({
            isin: $detailContainer.data('chart-isin'),
            underlyingName: $detailContainer.data('chart-underlying-name'),
            bidLabel: $detailContainer.data('chart-bid-label'),
            askLabel: $detailContainer.data('chart-ask-label'),
            limits: $detailContainer.data('chart-limits'),
            lang: ENVIRONMENT.language.lowercase,
            rangeSelectorLabels: {
                'Intraday': 'Intraday',
                '1 W': '1 W',
                '1 M': '1 M',
                '3 M': '3 M',
                '6 M': '6 M',
                '1 Y': '1 ' + rangeLabel,
                '3 Y': '3 ' + rangeLabel,
                'Max': 'Max'
            },
            loadingImageUrl: '/.resources/bcs-reloaded-config/files/loading.gif',
            chartContainerId: 'chart-container-detail',
            digits: $detailContainer.data('digits')
        });

        $('.chart-container .chart-button-group button.chart-button').on('click', function(){

            $('.chart-container .chart-button-group button.chart-button').removeClass('chart-button--active');

            var $this = $(this);
            $this.addClass('chart-button--active');

            var rangeType = $this.data('range-type');
            var range = $this.data('range');

            detailChartController.updateChart(rangeType, range);
        });
    }
});

var queo = queo || {};

queo.DetailChartController = function () {};

queo.DetailChartController.prototype.renderChartWithLimit = function (options) {

    $.extend(options, {
        isin: undefined,
        underlyingName: undefined,
        bidLabel: undefined,
        askLabel: undefined,
        limits: undefined,
        lang: undefined,
        rangeSelectorLabels: undefined,
        chartContainerId: undefined,
        digits: undefined
    });

    var controller = this;
    controller.renderChart(options, function(chartData) {
        if(options.limits && options.limits.forEach){
            options.limits.forEach(function(item, index){
                var limitData = [];

                var value = item.value.replace(/\./g, '').replace(',', '.');
                var isNumber = !isNaN(parseFloat(value)) && isFinite(value);
                if (isNumber) {

                    var limit = parseFloat(value);
                    var bidData = chartData[options.bidLabel].data;

                    if (bidData && bidData.length) {

                        var firstBid = bidData[0];
                        var firstDate = firstBid[0];

                        var lastBid = bidData[bidData.length - 1];
                        var lastDate = lastBid[0];

                        limitData.push([ firstDate, limit ]);
                        limitData.push([ lastDate, limit ]);

                        var label = item.label.replace('&shy;','');

                        chartData[label] = {
                            data : limitData,
                            yAxis: 1,
                            color: (index % 2 === 0) ? '#ff7800' : '#747982',
                            limit: true
                        };
                    }
                }
            })
        }
    });
};

queo.DetailChartController.prototype.renderDefaultChart = function (options) {
    $.extend(options, {
        isin: undefined,
        underlyingName: undefined,
        bidLabel: undefined,
        askLabel: undefined,
        lang: undefined,
        rangeSelectorLabels: undefined,
        digits: undefined
    });

    this.renderChart(options, function (chartData) {});
};

queo.DetailChartController.prototype.renderChart = function (options, callback) {
    var ctrl = this;
    ctrl.chart = new sg.DetailChart(options.lang, options.rangeSelectorLabels, options.loadingImageUrl, options.chartContainerId);

    $.getJSON(ENVIRONMENT.ajax.baseUrl + '/bcs_ajax/dailyChartDataForPeriod?isin=' + options.isin + '&chartPeriod=MAX', function (response) {

        var weekend = response.chartData.firstWeekend;

        var dailyData = ctrl.formatData(response.chartData.dailyData, options);
        var weekData = ctrl.formatData(response.chartData.weekData, options);
        var intradayData = ctrl.formatData(response.chartData.intradayData, options);

        // Based on the type of product, additional charts are added in this callback
        if (typeof callback === 'function') {
            callback(dailyData);
            callback(weekData);
            callback(intradayData);
        }

        ctrl.chart.draw(dailyData, weekData, intradayData, weekend, options.digits);
    });
};

queo.DetailChartController.prototype.formatData = function (data, options) {
    data.sort(function (a, b) {
        return a.date - b.date;
    });

    var dataForUnderlyings = {};
    var askData = [];
    var bidData = [];

    data.forEach(function (point) {

        if (point.underlyingValues !== undefined) {
            for (var property in point.underlyingValues) {
                if (point.underlyingValues.hasOwnProperty(property)) {

                    if (dataForUnderlyings[property] === undefined){
                        dataForUnderlyings[property] = [];
                    }

                    dataForUnderlyings[property].push([ point.date, point.underlyingValues[property]]);
                }
            }
        }
        bidData.push([point.date, point.bid]);
        askData.push([point.date, point.ask]);

    });

    var formattedData = {};
    var underlyingNames = options.underlyingName.split(",");

    var index = 0;
    for (var underlying in dataForUnderlyings) {
        if (dataForUnderlyings.hasOwnProperty(underlying)) {

            var underlyingName = underlying;
            if (index < underlyingNames.length){
                underlyingName = underlyingNames[index];
            }

            formattedData[underlyingName] = {
                data: dataForUnderlyings[underlying],
                yAxis: 1,
                color: '#747982',
                limit: false
            };

        }
    }

    formattedData[options.bidLabel] = {
        data: bidData,
        yAxis: 0,
        color: '#E5022F',
        limit: false
    };

    formattedData[options.askLabel] = {
        data: askData,
        yAxis: 0,
        color: '#78b627',
        limit: false
    };

    return formattedData;
};

queo.DetailChartController.prototype.updateChart = function (rangeType, range) {
    this.chart.redraw(rangeType, range);
};
