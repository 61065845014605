var sg = sg || {};
sg.ProductGroupList = sg.ProductGroupList || (function (jQuery)
{
    var ProductGroupList = function (initValues)
    {        
        var jWindow = jQuery("window");
        var jProductGroupWrapper = initValues.jProductGroupWrapper;        
        var jProductGroups = jProductGroupWrapper.find(".list-filter__type-product-group");
        var jCheckBox = jProductGroups.find("input.toggle-list");
                
        jQuery.extend(true, this,
        {
            jWindow: jWindow,
            jProductGroupWrapper: jProductGroupWrapper,
            jProductGroups: jProductGroups,
            jCheckBox: jCheckBox
        });
                
        initView.call(this);
    };
    
    var checkCheckBox = function checkCheckBox(checkbox)
    {
        var that = this;
        
        if(typeof checkbox !== 'undefined') {
            
            if (checkbox.is(":checked")) {                
                var currentProductGroup = checkbox.closest('.list-filter__type-product-group');
                that.jProductGroups.each(function(){
                    var productGroup = jQuery(this);
                    if(productGroup[0] != currentProductGroup[0]) {
                        productGroup.find('input.toggle-list').prop('checked', false);
                    } else {
                    }
                });
            }
        }
    };
    
    var checkBoxListener = function checkBoxListener()
    {
        var that = this;
        
        that.jCheckBox.on("change", function ()
        {
            checkCheckBox.call(that, jQuery(this));
        });
    };
    
    var initView = function initView()
    {
        checkBoxListener.call(this);
        checkCheckBox.call(this);
    };
    
    return ProductGroupList;
}(jQuery));