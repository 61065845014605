var sg = sg || {};

sg.FastSearchChart = function (initValues) {

    this.lang = initValues.lang;
    this.rangeSelectorLabels = initValues.rangeSelectorLabels;
    this.loadingImageUrl = initValues.loadingImageUrl;
    this.chartContainerId = initValues.chartContainerId;
    this.digits = 2;

    this.init();
};

sg.FastSearchChart.prototype.init = function() {
    this.initChart();
    this.checkChartItemsOnResize();
};

sg.FastSearchChart.prototype.checkChartItemsOnResize = function() {
    var ctrl = this;
    var jWindow = jQuery(window);

    jWindow.on("resize", function () {
        ctrl.repositionChartItems();
    });
};

sg.FastSearchChart.prototype.repositionChartItems = function() {
    sg.FastSearchChart.chart.reflow();
};

sg.FastSearchChart.prototype.initChart = function() {

    this.jChartContainer = $('#'+this.chartContainerId);
    this.chartContainerWidth = this.jChartContainer.width();

    var chartFormatController = new sg.ChartFormatController(this.lang);

    var _this = this;

    sg.FastSearchChart.chart = Highcharts.stockChart(_this.chartContainerId, {
        lang: {
            rangeSelectorZoom: "",
            noData: ''
        },
        noData: {
            useHTML: true,
            position: {
                align: 'center',
                verticalAlign: 'middle'
            },
            style: {
                color: '#e5022f',
                fontSize: '12px',
                fontWeight: 'bold'
            }
        },
        chart: {
            height: 330,
            spacing: [0, 0, 45, 0],
            marginTop: 0,
            marginBottom: 70,
            resetZoomButton: {
                theme: {
                    display: 'none'
                }
            },
            stlye: {
                color: "#999999"
            }
        },
        xAxis: {
            tickPositioner: function () {
                var positions = [],
                    tick = Math.floor(this.dataMin),
                    oneHour = 60 * 60 * 1000,
                    offset = tick % oneHour;

                if (offset > 0) {
                    tick -= offset;
                }

                var increment = oneHour;

                switch (sg.FastSearchChart.chartRange){
                    case 'intraday':
                        increment *= 2; break;
                    case '1 W':
                        increment *= 24; break;
                    case '1 M':
                        increment *= 24 * 7; break;
                    default:
                        increment *= 24 * 7 * 4;
                }

                if (this.dataMax !== null && this.dataMin !== null) {
                    for (tick; tick - increment <= this.dataMax; tick += increment) {
                        positions.push(tick);
                    }
                }

                return positions;
            },
            minRange: 1000 * 60 * 60,
            ordinal: false,
            crosshair: {
                color: '#595c65',
                crosshair: true,
                dashStyle: 'LongDash'
            },
            type: 'datetime',
            labels: {
                formatter: function () {
                    return chartFormatController.formatXAxisLabel(this, sg.FastSearchChart.chartRange);
                },
                style: {
                    color: "#999999"
                }
            },
            gridLineColor: "#f7f7f7",
            gridLineDashStyle: "Solid",
            gridLineWidth: 1,
            lineColor: "#999999",
            tickColor: "#999999"
        },
        yAxis: {
            crosshair: {
                color: '#595c65',
                crosshair: true,
                dashStyle: 'LongDash'
            },
            labels: {
                align: 'left',
                formatter: function () {
                    return chartFormatController.formatYAxisLabel(this, sg.FastSearchChart.digits);
                },
                style: {
                    color: "#999999"
                }
            },
            gridLineColor: "#f7f7f7",
            gridLineDashStyle: "Solid",
            gridLineWidth: 1
        },
        scrollbar: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        colors: [
            '#7b7d82',
            '#ff00ff',
            '#ff00ff',
            '#ff00ff',
            '#1aadce',
            '#492970',
            '#f28f43',
            '#77a1e5',
            '#c42525',
            '#a6c96a'
        ],
        plotOptions: {
            series: {
                color: "#999999",
                marker: {
                    fillColor: "#595c65",
                    radius: 4,
                    states: {
                        hover: {
                            enabled: true,
                            lineWidthPlus: 0,
                            radiusPlus: 0
                        }
                    }
                },
                states: {
                    hover: {
                        halo: {
                            attributes: {
                                opacity: 0,
                                size: 0
                            }
                        }
                    }
                }
            },
            line: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                }
            }
        },
        loading: {
            labelStyle: {
                backgroundImage: 'url('+ _this.loadingImageUrl +')',
                display: 'block',
                width: '87px',
                height: '87px',
                margin: '0 auto',
                top: '35%'
            }
        },

        tooltip: {
            backgroundColor: "#595C65",
            borderRadius: 0,
            borderWidth: 0,
            shadow: false,
            xDateFormat: '%Y',
            pointFormat: '<b>{point.y} | {point.category}</b><br/>',
            headerFormat: '',
            formatter: function () {
                var displaySeriesName = false;
                return chartFormatController.formatTooltip(this, sg.FastSearchChart.chartRange, sg.FastSearchChart.digits, displaySeriesName);
            },
            style: {
                color: "white"
            }
        },
        navigation: {
            buttonOptions: {
                align: "bottom",
                enabled: true,
                height: 20,
                symbolFill: "#ff00ff",
                symbolSize: 14,
                symbolStroke: "#ff00ff",
                symbolStrokeWidth: 1,
                symbolX: 12.5,
                symbolY: 10.5,
                text: null,
                verticalAlign: 'bottom',
                y: -20
            }
        },
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: false
        },
        title: {
            text: ""
        }
    });

    sg.FastSearchChart.chart.showLoading('&nbsp;');
};

sg.FastSearchChart.prototype.draw = function(dailyData, weekData, intradayData, weekend, digits){
    var chart = sg.FastSearchChart.chart;
    sg.FastSearchChart.dailyData = dailyData;
    sg.FastSearchChart.weekData = weekData;
    sg.FastSearchChart.intradayData = intradayData;
    sg.FastSearchChart.chartRange = "intraday";
    sg.FastSearchChart.digits = digits;

    var xAxis = chart.xAxis[0];

    var redraw = false;

    while(chart.series.length > 0) {
        chart.series[0].remove(redraw);
    }

    chart.addSeries({
        name: 'Underlying',
        data: sg.FastSearchChart.intradayData,
        tooltip: {
            valueDecimals: 2
        }
    }, redraw);

    var breaks = [];
    if (weekend && weekend.start && weekend.end) {

        var start = new Date(weekend.start);
        breaks = [{
            from: weekend.start,
            to: weekend.end,
            breakSize: 0,
            repeat: 7 * 24 * 36e5
        },{
            from: 1511820000000,
            to: 1511851800000,
            breakSize: 0,
            repeat: 24 * 36e5
        }];
    }

    xAxis.update({
        breaks: breaks
    });

    $($('svg').find('.highcharts-button').get(0)).click();

    chart.hideLoading();
};

sg.FastSearchChart.prototype.redraw = function(rangeType, range) {
    if (sg.FastSearchChart.chartRange !== rangeType) {
        sg.FastSearchChart.chartRange = rangeType;

        var data;
        switch (sg.FastSearchChart.chartRange) {
            case "intraday":
                data = sg.FastSearchChart.intradayData;
                break;
            case "1 W":
                data = sg.FastSearchChart.weekData;
                break;
            default:
                data = sg.FastSearchChart.dailyData;
        }

        var chart = sg.FastSearchChart.chart;
        chart.series[0].remove(false);
        chart.addSeries({
            name: 'Underlying',
            data: data,
            tooltip: {
                valueDecimals: 2
            }
        }, false);

        setTimeout(function () {
            if (range === undefined) {

                chart.xAxis[0].setExtremes();
            } else {
                var lastDateTime = data[data.length - 1][0];
                var firstDateTime = data[0][0];
                var max = lastDateTime;
                var min = lastDateTime - range;
                if (min < firstDateTime) {
                    min = firstDateTime;
                }
                chart.xAxis[0].setExtremes(min, max);
            }

            chart.redraw();
        });
    }
};
