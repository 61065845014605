var watchListCtrl;

jQuery(document).ready(function(){
    (function($) {
        watchListCtrl = new queo.WatchListController();

        /* init Watchlist badge in Nav */
        watchListCtrl.updateWatchListBadge($('.news-indicator .badge'));

        $('[data-wl-remove]').on('click', function(){
            watchListCtrl.removeProduct($(this).data('isin'));
            location.reload();
        });

        $('[data-wl-contact]').on('click', function(){
            new queo.ContactFormController({
                form: $($('.tooltipster-content form.contactForm')[0]),
                successMessage: '<p>Ihre Nachricht wurde erfolgreich verschickt.</p>'
            });
        });

        var $listTableWatchIcons = $('.list-table__eye');

        $listTableWatchIcons.each(function(){

            var $element = $(this);
            var isin = $element.data('isin');

            if (watchListCtrl.watchListContainsIsin(isin)){
                watchListCtrl.markAsIncludedInWatchList($element);
            }
        });

        $listTableWatchIcons.on('click', function(){

            var isin = $(this).data('isin');

            if($(this).hasClass("list-table__eye--active")){
                watchListCtrl.removeProduct(isin);
                watchListCtrl.toggleIncludedInWatchList($(this));

            } else {
                watchListCtrl.addProduct(isin);
                watchListCtrl.toggleIncludedInWatchList($(this));

            }
        });

        var $productDetailsWatchIcons = $('.product-details__eye-icon');

        $productDetailsWatchIcons.each(function(){

            var $element = $(this);
            var isin = $element.data('isin');

            if (watchListCtrl.watchListContainsIsin(isin)){
                watchListCtrl.markAsIncludedInWatchListProductDetailPage($element);
            }
        });

        $productDetailsWatchIcons.on('click', function(){

            var isin = $(this).data('isin');

            if($(this).hasClass("product-details__eye-icon--active")){
                watchListCtrl.removeProduct(isin);
                watchListCtrl.toggleIncludedInWatchListProductDetailPage($(this));
            } else {
                watchListCtrl.addProduct(isin);
                watchListCtrl.toggleIncludedInWatchListProductDetailPage($(this));
            }
        });


    })(jQuery);
});

var queo = queo || {};

queo.WatchListController = function () {};

queo.WatchListController.prototype.addProduct = function (isin) {
    var controller = this;

    var watchList = getWatchList();

    if(!watchList.includes(isin)){

        watchList.push(isin);
        setWatchList(watchList);

        controller.updateWatchListBadge($('.news-indicator .badge'));
    }
};

queo.WatchListController.prototype.removeProduct = function (isin) {
    var controller = this;

    var watchList = getWatchList();
    if(watchList.includes(isin)){
        var index = watchList.indexOf(isin);
        watchList.splice(index, 1);
        setWatchList(watchList);
        controller.updateWatchListBadge($('.news-indicator .badge'));
    }
};

queo.WatchListController.prototype.updateWatchListBadge = function ($watchListBadge) {
    var watchList = getWatchList();
    $watchListBadge.html(watchList.length);
};

queo.WatchListController.prototype.markAsIncludedInWatchList = function($element){
    $element.addClass('list-table__eye--active');
};

queo.WatchListController.prototype.toggleIncludedInWatchList = function($element){
    $element.toggleClass('list-table__eye--active');
    if($element.hasClass('list-table__eye--active'))
        this.animateAddToWatchList($element);
};

queo.WatchListController.prototype.markAsIncludedInWatchListProductDetailPage = function($element) {
    $element.addClass('product-details__eye-icon--active');
};

queo.WatchListController.prototype.toggleIncludedInWatchListProductDetailPage = function($element){
    $element.toggleClass('product-details__eye-icon--active');
    if($element.hasClass('product-details__eye-icon--active'))
        this.animateAddToWatchListProductDetailPage($element);
};

queo.WatchListController.prototype.animateAddToWatchList = function($element){
    var newsIndicatorPosition = document.getElementsByClassName('news-indicator')[0].getBoundingClientRect();

    var clone = $element.clone().appendTo($element.parent())
        .css({'position': 'absolute','top':'16px','left':'8px','z-index':'1000'});

    var clonePosition = clone.get(0).getBoundingClientRect();

    clone.animate({
        'top': '-=' + (clonePosition.top - newsIndicatorPosition.top - 10),
        'left': '-=' + (clonePosition.left - newsIndicatorPosition.left - 7),
        'opacity': 0
    }, 'slow', function(){ clone.remove(); });
};

queo.WatchListController.prototype.animateAddToWatchListProductDetailPage = function($element){
    var newsIndicatorPosition = document.getElementsByClassName('news-indicator')[0].getBoundingClientRect();

    var clone = $element.clone().appendTo($element.parent())
        .css({'position': 'absolute', 'z-index':'1000'});

    var clonePosition = clone.get(0).getBoundingClientRect();

    clone.animate({
        'top': '-=' + (clonePosition.top - newsIndicatorPosition.top - 10),
        'left': '-=' + (clonePosition.left - newsIndicatorPosition.left - 7),
        'opacity': 0
    }, 'slow', function(){ clone.remove(); });
};

queo.WatchListController.prototype.watchListContainsIsin = function(isin){
    return getWatchList().includes(isin);
};

queo.WatchListController.prototype.getWatchList = getWatchList;

function getWatchList() {
    var watchList = $.cookie("watchList");
    if (watchList === null || watchList === undefined){
        watchList = [];
    } else{
        watchList = JSON.parse(watchList);
    }
    return watchList;
}

function setWatchList(watchList) {
    $.cookie("watchList",JSON.stringify(watchList), {path: '/'});
}
