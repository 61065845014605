var sg = sg || {};

sg.DetailChart = function (lang, rangeSelectorLabels, loadingImageUrl, chartContainerId) {

    jQuery.extend(true, this, {
        lang: lang,
        rangeSelectorLabels: rangeSelectorLabels,
        loadingImageUrl: loadingImageUrl,
        chartContainerId: chartContainerId,
        digits: 2
    });

    initChart.call(this);
};

sg.DetailChart.prototype.draw = function(dailyData, weekData, intradayData, weekend, digits){
    sg.DetailChart.dailyData = dailyData;
    sg.DetailChart.weekData = weekData;
    sg.DetailChart.intradayData = intradayData;
    sg.DetailChart.digits = digits;
    sg.DetailChart.chartRange = 'intraday';

    var chart = sg.DetailChart.chart;
    updateSeriesAndReturnLastDateTime(chart, intradayData, weekend);
    chart.redraw();
    chart.hideLoading();
};

sg.DetailChart.prototype.redraw = function(rangeType, range){
    if (sg.DetailChart.chartRange !== rangeType) {
        sg.DetailChart.chartRange = rangeType;

        var data;
        switch (sg.DetailChart.chartRange) {
            case "intraday":
                data = sg.DetailChart.intradayData;
                break;
            case "1 W":
                data =  sg.DetailChart.weekData;
                break;
            default:
                data = sg.DetailChart.dailyData;
        }

        var chart = sg.DetailChart.chart;
        var dateTime = updateSeriesAndReturnLastDateTime(chart, data);

        if (range === undefined) {
            chart.xAxis[0].setExtremes();
        } else {
            var min = dateTime.last - range;
            var max = dateTime.last;

            if (min < dateTime.first) {
                min = dateTime.first;
            }

            chart.xAxis[0].setExtremes(min, max);
        }

        chart.redraw();
    }
};

var updateSeriesAndReturnLastDateTime = function(chart, data, weekend){

    var length = chart.series.length;
    for (var i = 0; i < length; i++) {
        chart.series[0].remove(false);
    }

    var lastDateTime = 0;
    var firstDateTime = new Date().getTime();

    for (var key in data) {

        var series = data[key];
        var currentData = series.data;
        if (currentData.length > 0) {
            var lastPoint = currentData[currentData.length - 1 ][0];
            if (lastPoint > lastDateTime) {
                lastDateTime = lastPoint;
            }
            var firstPoint = currentData[0][0];
            if (firstPoint < firstDateTime){
                firstDateTime = firstPoint;
            }
        }

        chart.addSeries({
            name: key,
            data: series.data,
            yAxis: series.yAxis,
            color: series.color,
            visible: !series.limit,
            selected: !series.limit,
            checkbox: {
                style: {
                    marginLeft: '-30px'
                }
            }
        }, false);
    }

    /* hide weekends from chart, as there are no points */
    var breaks = [];
    var xAxis = chart.xAxis[0];

    if (weekend && weekend.start && weekend.end) {
        breaks = [{
            from: weekend.start,
            to: weekend.end,
            breakSize: 0,
            repeat: 7 * 24 * 36e5
        },{
            from: 1511820000000,
            to: 1511851800000,
            breakSize: 0,
            repeat: 24 * 36e5
        }];

        xAxis.update({
            breaks: breaks
        }, false);
    }

    return {last: lastDateTime, first: firstDateTime};
};

function initChart() {
    var _this = this;

    sg.DetailChart.chartFormatController = new sg.ChartFormatController(this.lang);

    sg.DetailChart.chart = Highcharts.stockChart(_this.chartContainerId, {
        lang: {
            noData: 'Keine Daten verfügbar.'
        },
        chart: {
            type: 'line',
            alignTicks: false,
            backgroundColor: 'transparent',
            marginTop: 84
        },
        noData: {
            useHTML: true,
            position: {
                align: 'center',
                verticalAlign: 'middle'
            },
            style: {
                color: '#e5022f',
                fontSize: '12px',
                fontWeight: 'bold'
            }
        },
        plotOptions: {
            series: {
                allowPointSelect: true,
                showCheckbox: true,
                marker: {
                    enabled: false
                },
                events: {
                    checkboxClick: function (event) {
                        if (event.checked) {
                            this.show();
                        }
                        else {
                            this.hide();
                        }
                    },
                    legendItemClick: function (event) {
                        var jCheckbox = jQuery(this.checkbox);

                        if (this.visible) {
                            jCheckbox.prop("checked", false);
                        } else {
                            jCheckbox.prop("checked", true);
                        }
                    }
                }
            },
            line: {
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                }
            }
        },
        legend: {
            enabled: true,
            useHTML: true,
            align: 'left',
            verticalAlign: 'top',
            floating: false,
            backgroundColor: '#FFFFFF',
            x: -10,
            symbolPadding: 0,
            symbolWidth: 0,
            itemDistance: 50,
            labelFormatter: function () {
                return '<span style="color: ' + this.color + ';">' + this.name + '</span>'
            }
        },
        loading: {
            labelStyle: {
                backgroundImage: 'url('+ _this.loadingImageUrl +')',
                display: 'block',
                width: '87px',
                height: '87px',
                left: '45%',
                top: '40%'
            }
        },
        navigator: {
            enabled: false
        },
        rangeSelector: {
            enabled: true,
            inputEnabled: false,
            buttons: [{
                type: 'hour',
                count: 1,
                text: _this.rangeSelectorLabels['Intraday']
            }],
            labelStyle: {
                display: "none",
                visibility: 'hidden'
            },
            buttonTheme: {
                style: {
                    display: "none"
                }
            }
        },
        exporting: {
            enabled: false
        },
        scrollbar: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        subtitle: {
            text: '',
            x: -20
        },
        tooltip: {
            backgroundColor: "#595C65",
            borderRadius: 0,
            borderWidth: 0,
            shadow: false,
            xDateFormat: '%Y',
            pointFormat: '<b>{point.y} | {point.category}</b><br/>',
            headerFormat: '',
            formatter: function () {
                var displaySeriesName = true;
                return sg.DetailChart.chartFormatController.formatTooltip(this, sg.DetailChart.chartRange, sg.DetailChart.digits, displaySeriesName);
            },
            style: {
                color: "white"
            }
        },
        xAxis: {
            tickPositioner: function () {

                if (sg.DetailChart.chartRange){

                    var positions = [],
                        tick = Math.floor(this.dataMin);

                    var oneHour = 60 * 60 * 1000;
                    var offset = tick % oneHour;

                    if (offset > 0) {
                        tick -= offset;
                    }

                    var increment = oneHour;
                    var range = this.dataMax - this.dataMin;

                    switch (sg.DetailChart.chartRange){
                        case 'intraday': increment = oneHour * 2; break;
                        case '1 W': increment = oneHour * 24; break;
                        case '1 M': increment = oneHour * 24 * 7; break;
                        case '3 M': increment = oneHour * 24 * 7 * 4; break;
                        case '6 M': increment = oneHour * 24 * 7 * 8; break;
                        case '1 Y':
                            increment = oneHour * 24 * 7 * 4 * 6; break;
                        default:
                            increment = oneHour * 24 * 7 * 4 * 12;
                    }

                    if (this.dataMax !== null && this.dataMin !== null) {
                        for (tick; tick - increment <= this.dataMax; tick += increment) {
                            positions.push(tick);
                        }
                    }
                    return positions;
                }

                return [];

            },
            //minRange is 1 Hour
            minRange: 1000 * 60 * 60,
            ordinal: false,
            crosshair: {
                color: '#595c65',
                crosshair: true,
                dashStyle: 'LongDash'
            },
            type: 'datetime',
            labels: {
                formatter: function () {
                    return sg.DetailChart.chartFormatController.formatXAxisLabel(this, sg.DetailChart.chartRange);
                },
                style: {
                    color: "#999999"
                }
            },
            gridLineColor: "#f7f7f7",
            gridLineDashStyle: "Solid",
            gridLineWidth: 1,
            lineColor: "#999999",
            tickColor: "#999999"
        },
        yAxis: [{
            opposite: false,
            plotLines: [{
                value: 0,
                width: 1,
                color: '#808080'
            }],
            crosshair: {
                color: '#595c65',
                crosshair: true,
                dashStyle: 'LongDash'
            },
            labels: {
                formatter: function () {
                    return sg.DetailChart.chartFormatController.formatYAxisLabel(this, sg.DetailChart.digits);
                },
                style: {
                    color: "#999999"
                }
            },
            gridLineColor: "#f7f7f7",
            gridLineDashStyle: "Solid",
            gridLineWidth: 1
        }, {
            opposite: true,
            plotLines: [{
                value: 0,
                width: 1,
                color: '#808080'
            }],
            crosshair: {
                color: '#595c65',
                crosshair: true,
                dashStyle: 'LongDash'
            },
            labels: {
                align: 'left',
                formatter: function () {
                    return sg.DetailChart.chartFormatController.formatYAxisLabel(this, sg.DetailChart.digits);
                },
                style: {
                    color: "#999999"
                }
            },
            gridLineColor: "#f7f7f7",
            gridLineDashStyle: "Solid",
            gridLineWidth: 1
        }]
    });

    sg.DetailChart.chart.showLoading('&nbsp;');
}
