function pad(n, width, z) {
    z = z || '0';
    n = n + '';
    return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
}

function formatDate(date, format) {
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var day = date.getDate();

    if(format == 'de-DE'){
        return  pad(day,2)+ "." + pad(month,2)  +'.' + year;
    } else {
        return pad(month,2) + "/" + pad(day,2) + '/' + year;
    }
}

var sg = sg || {};
sg.RangeSlider = sg.RangeSlider || (function (jQuery) {
    function RangeSlider(initValues) {
        var jRangeSlider = initValues.jRangeSlider;
        var jRangeSliderWrapper = jRangeSlider.parents(".list-filter__range-wrapper");
        var jMinInput = jRangeSliderWrapper.find(".min");
        var jMaxInput = jRangeSliderWrapper.find(".max");
        var min = jRangeSlider.data("min");
        var max = jRangeSlider.data("max");
        var type = jRangeSlider.data("type");
        var suffix = jRangeSlider.data("suffix");
        var localisation = jQuery("html").attr("lang");
        var lang = "";

        localisation == "de" ? lang = "de-DE" : lang = "en-GB";

        jQuery('.list-filter__range-input--placeholder').on('click', function(){
            var input = jQuery(this).siblings('input');
            jQuery('.list-filter__range-input--placeholder').css('display','none');
            input.focus();
        });
        
        jQuery.extend(true, this, {
            jRangeSlider: jRangeSlider,
            jRangeSliderWrapper: jRangeSliderWrapper,
            jMinInput: jMinInput,
            jMaxInput: jMaxInput,
            min: min,
            max: max,
            type: type,
            suffix: suffix,
            lang: lang
        });

        initView.call(this);
    }

    function initFloatRangeListener() {
        var that = this;
        var suffix = that.suffix;

        if(typeof suffix === "undefined") {
            suffix = "";
        }

        var decimals = undefined;

        if(that.type === 'number-3') {
            decimals = {minimumFractionDigits: 3, maximumFractionDigits: 3};
        } else if (that.type === 'number-4'){
            decimals = {minimumFractionDigits: 4, maximumFractionDigits: 4};
        }

        that.jMinInput.val(that.jMinInput.val().toLocaleString(that.lang, decimals) + "" + suffix);
        that.jMaxInput.val(that.jMaxInput.val().toLocaleString(that.lang, decimals) + "" + suffix);

        that.jRangeSlider.on("valuesChanging", function (e, data) {
            that.jMaxInput.val(data.values.max.toLocaleString(that.lang, decimals) + "" + suffix);
            that.jMinInput.val(data.values.min.toLocaleString(that.lang, decimals) + "" + suffix);
        });
    }

    function initNumberRangeListener() {
        var that = this;
        var suffix = that.suffix;

        if(typeof suffix === "undefined") {
            suffix = "";
        }

        that.jMinInput.val(parseFloat(that.jMinInput.val()).toLocaleString(that.lang) + "" + suffix);
        that.jMaxInput.val(parseFloat(that.jMaxInput.val()).toLocaleString(that.lang) + "" + suffix);
        
        that.jRangeSlider.on("valuesChanging", function (e, data) {
            that.jMaxInput.val(parseFloat(data.values.max).toLocaleString(that.lang) + "" + suffix);
            that.jMinInput.val(parseFloat(data.values.min).toLocaleString(that.lang) + "" + suffix);
        });
    }

    function initDateRangeListener() {
        var that = this;

        var jMinInput = new Date(that.jMinInput.attr('value'));
        that.jMinInput.val(formatDate(jMinInput,that.lang));

        var jMaxInput = new Date(that.jMaxInput.attr('value'));
        that.jMaxInput.val(formatDate(jMaxInput,that.lang));

        that.jRangeSlider.on("valuesChanging", function (e, data) {
            var maxDate = new Date(that.max);
            var dateMin = data.values.min;
            var dateMax = data.values.max;

            that.jMinInput.val(formatDate(dateMin,that.lang));

            if (data.values.max.getTime() === maxDate.getTime()){
                jQuery('.list-filter__range-input--placeholder').css('display','block');
            } else {
                jQuery('.list-filter__range-input--placeholder').css('display','none');
                that.jMaxInput.val(formatDate(dateMax,that.lang));
            }    
        });
    }

    function parse(value) {
        if (isNaN(value)){
            value = value.replace(',','.');
        }
        return parseFloat(value);
    }

    function initRangeSlider() {
        var that = this;

        if(that.type == "number-3" || that.type == "number-4") {

            that.jRangeSlider.rangeSlider({
                valueLabels: "hide",
                type: that.type,
                symmetricPositionning: true,
                defaultValues: {
                    min: parse(that.jMinInput.val()),
                    max: parse(that.jMaxInput.val())
                },
                bounds: {
                    min: parse(that.min),
                    max: parse(that.max)
                }
            });

            initFloatRangeListener.call(that);

        } else if (that.type == "number") {
            that.jRangeSlider.rangeSlider({
                valueLabels: "hide",
                type: that.type,
                symmetricPositionning: true,
                defaultValues: {
                    min: parseFloat(that.jMinInput.val()),
                    max: parseFloat(that.jMaxInput.val())
                },
                bounds: {
                    min: parseFloat(that.min),
                    max: parseFloat(that.max)
                }
            });
            
            initNumberRangeListener.call(that);

        } else if (that.type === "date") {
            that.jRangeSlider.dateRangeSlider({
                valueLabels: "hide",
                type: "date",
                symmetricPositionning: true,
                defaultValues: {
                    min: new Date(that.jMinInput.val()),
                    max: new Date(that.jMaxInput.val())
                },
                bounds: {
                    min: new Date(that.min),
                    max: new Date(that.max)
                }
            });
            
            initDateRangeListener.call(that);
        }
    }

    function initView() {
        initRangeSlider.call(this);
    }

    return RangeSlider;
}(jQuery));