(function($){
    if($(".contact-badge").length) {
        $(window).on("scroll resize", function() {
            updateContactBadgePosition();
        });

        updateContactBadgePosition();
    }

    function updateContactBadgePosition() {
        var jContactBadge = $(".contact-badge");
        var scrolled = $(window).scrollTop();

        jContactBadge.css("margin-top", scrolled);
    }
}(jQuery));

