document.addEventListener("DOMContentLoaded", function() {
    (function($){
        var dataSelector = 'equalize-height';
        var selector = '[data-equalize-height]';
        var breakpoint = 767;

        var $elementsToEqualize = $(selector);
        var groupIds = [];
        var groups = {};

        $elementsToEqualize.each(function(index, element){
            var elementGroupId = $(element).data(dataSelector);

            if(!groupIds.includes(elementGroupId)){
                groupIds.push(elementGroupId);
                groups[elementGroupId] = [];
            }

            groups[elementGroupId].push(element);
        });

        Object.keys(groups).forEach(function(group) {

            if(groups[group].length > 1){
                $(window).on('load resize', function () {
                    if (window.innerWidth > breakpoint) {
                        equalizeProperty(groups[group], 'height');
                    } else {
                        groups[group].forEach(function(element){
                            element.removeAttribute('style');
                        })
                    }
                });
            }
        })
    }(jQuery));
});

function equalizeProperty(elements, property){

    var allStyled = true;

    var heights = $(elements).map(function(index, element) {
        var height = $(element).find('p').height();

        if (element.hasAttribute('style') && element.clientHeight >= height){
            element.removeAttribute('style');
        }

        if (!element.hasAttribute('style')){ allStyled = false; }

        return {height: $(element).height(), hasStyle: element.hasAttribute('style')};
    });

    var maxHeight = heights.get().reduce(function(a, b) {
        return Math.max(a.height, b.height);
    });

    elements.forEach(function(element){
        if(element.clientHeight < maxHeight){
            element.setAttribute('style', property + ':' + maxHeight + 'px');
        }
    });
}
