jQuery(document).ready(function(){
    (function($) {
        if (!ENVIRONMENT.device.isMobile && jQuery('.horizontal-container__info').length) {
            $('.horizontal-container__info').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                functionBefore: function (instance, helper){
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                side: ['left', 'right', 'bottom'],
                theme: "info",
                debug: false
            });
        } else if ($('.horizontal-container__info').length) {
            $('.horizontal-container__info').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen:{
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose:{
                    click: true,
                    scroll: true,
                    tap: true
                },
                theme: "info",
                debug: false
            });
        }

        if($('.toolipster').length){
            $('.toolipster').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper){
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                functionReady: function (instance){
                    var jTooltip = instance._$tooltip;

                    $('.detail-tooltip__close').click(function(){
                        instance.close();
                    });
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "product",
                debug: false
            });
        }

        if($('.info-toolipster').length) {
            $('.info-toolipster').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper){
                    var jOrigin = jQuery(helper.origin);
                    var dataTitle = jOrigin.data("title");
                    if (dataTitle) {
                        instance.content(dataTitle);
                    } else {
                        var contentId = jOrigin.data("tooltipid");
                        var jContent = jQuery("#" + contentId);

                        instance.content(jContent);
                    }
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    mouseenter: true,
                    touchstart: true
                },
                triggerClose: {
                    mouseleave: true,
                    click: true,
                    scroll: true,
                    tap: true
                },
                theme: "info",
                debug: false
            });
        }

        var theme;

        if ($('.toolipster-dynamic').length) {
            $('.toolipster-dynamic').tooltipster({
                contentAsHTML: true,
                trackOrigin: true,
                distance: 135,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var theme = jOrigin.data("theme");
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                functionReady: function (instance)
                {
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    mouseenter: true,
                    click: true,
                    tap: true
                },
                triggerClose: {
                    mouseleave: true,
                    click: true,
                    tap: true
                },
                theme: theme || "product",
                debug: false
            });
        }

        if ($('.toolipster-dynamic-contact').length){
            $('.toolipster-dynamic-contact').tooltipster({
                contentAsHTML: true,
                trackOrigin: true,
                distance: 135,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    var wkn = jOrigin.data("wkn");
                    var subject = jContent.find('input[name="subject"]');
                    subject.val("Frage zu " + wkn);

                    instance.content(jContent);
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    mouseenter: true,
                    click: true,
                    tap: true
                },
                triggerClose: {
                    mouseleave: true,
                    click: true,
                    tap: true
                },
                theme: "watch",
                debug: false
            });
        }

        if($('.toolipster-red').length){
            $('.toolipster-red').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper){
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                functionReady: function (instance){
                    var jTooltip = instance._$tooltip;

                    $('.detail-tooltip__close').click(function(){
                        instance.close();
                    });
                },
                side: ['right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "sell",
                debug: false
            });
        }

        if($('.producttable__prib').length) {
            $('.producttable__prib').tooltipster({
                arrow: false,
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "pib",
                debug: false
            });
        }

        if($('.documents__list--pib').length){
            $('.documents__list--pib').tooltipster({
                arrow: false,
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                functionBefore: function (instance, helper) {
                    $('body').addClass('no-scroll');
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                functionAfter: function(){
                    $('body').removeClass('no-scroll');
                },
                side: ['left'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "pib",
                debug: false
            });
        }

        if($('.contact-badge').length){
            $('.contact-badge').tooltipster({
                arrow: false,
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                maxWidth: 400,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "contact",
                debug: false
            });
        }

        if($('.watch-contact').length){
            $('.watch-contact').tooltipster({
                contentAsHTML: true,
                contentCloning: true,
                interactive: true,
                maxWidth: 400,
                functionBefore: function (instance, helper) {
                    var jOrigin = $(helper.origin);
                    var contentId = jOrigin.data("tooltipid");
                    var jContent = $("#" + contentId);

                    instance.content(jContent);
                },
                functionReady: function (instance)
                {
                },
                side: ['left', 'right', 'bottom'],
                trigger: 'custom',
                triggerOpen: {
                    click: true,
                    tap: true
                },
                triggerClose: {
                    click: true,
                    tap: true
                },
                theme: "watch",
                debug: false
            });
        }

        $(".tooltipstered").on("click", function(event){
            event.preventDefault();
        });
    }(jQuery))
});

jQuery(document).ready(function() {
    (function($) {
        var dialogClose = $('.pib__dialog-close');
        var dialogSubmit = $('.pib__form [type="submit"]');

        dialogClose.on('click', function(){
            $('.documents__list--pib').tooltipster('hide');
        });

        dialogSubmit.on('click', function(){
            $('.documents__list--pib').tooltipster('hide');
        });
    })(jQuery);
});