window.addEventListener('load', function() {

    var linktext = 'Mehr erfahren';
    var messagetext = 'Diese Webseite verwendet Cookies. Wenn Sie diese Webseite nutzen, akzeptieren Sie die Verwendung von Cookies.';

    if(ENVIRONMENT.language.lowercase === 'en'){
        linktext = 'Learn more';
        messagetext = 'This site uses cookies.';
    }

    if (!window.cookieconsent_options) { window.cookieconsent_options = {}; }
    if (!window.cookieconsent_options.position) { window.cookieconsent_options.position = 'top'; }
    if (!window.cookieconsent_options.layout) { window.cookieconsent_options.layout = 'container'; }
    if (!window.cookieconsent_options.layouts) { window.cookieconsent_options.layouts = {'container': '<div class="container-fluid wrapper">\{{messagelink}}{{compliance}}</div>'}; }
    if (!window.cookieconsent_options.content) { window.cookieconsent_options.content = {}; }
    if (!window.cookieconsent_options.content.message) { window.cookieconsent_options.content.message = messagetext; }
    if (!window.cookieconsent_options.content.dismiss) { window.cookieconsent_options.content.dismiss = ''; }
    if (!window.cookieconsent_options.content.link) { window.cookieconsent_options.content.link = linktext; }
    if (!window.cookieconsent_options.content.href) { window.cookieconsent_options.content.href = '/' + ENVIRONMENT.language.lowercase + '/data-privacy.html'; }

    window.cookieconsent_options.elements = {
        message: '<p id="cookieconsent:desc" class="cc-message">{{message}}</p>',
        messagelink: '<p id="cookieconsent:desc" class="cc-message">{{message}} <a aria-label="learn more about cookies" role=button tabindex="0" class="cc-link" href="{{href}}" target="_blank">{{link}}</a></p>'
    };

    // Initialisierung des Cookie-Dialogs
    window.cookieconsent.initialise(window.cookieconsent_options);
});