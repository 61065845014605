var sg = sg || {};

sg.Slider = sg.Slider || (function (jQuery) {
    var buttomTemplatePrev = '<button data-role="none" class="slick-prev" aria-label="Prev" role="button"></button>';
    var buttomTemplateNext = '<button data-role="none" class="slick-next" aria-label="Next" role="button"></button>';


    var Slider = function (initValues) {
        var jNewsSlider = initValues.jNewsSlider;
        var jProductsSlider = initValues.jProductsSlider;
        var jTabsSlider = initValues.jTabsSlider;
        var jMarketsListSlider = initValues.jMarketsListSlider;
        var jMarketsTickerSlider = initValues.jMarketsTickerSlider;
        var jFurtherNewsletterSlider = initValues.jFurtherNewsletterSlider;
        var jNewsletterSubscriptionSlider = initValues.jNewsletterSubscriptionSlider;
        var jCurrentNewsletterSlider = initValues.jCurrentNewsletterSlider;
        var jWebinareCategoriesSlider = initValues.jWebinareCategoriesSlider;

        jQuery.extend(true, this, {
            jNewsSlider: jNewsSlider,
            jProductsSlider: jProductsSlider,
            jTabsSlider: jTabsSlider,
            jMarketsListSlider: jMarketsListSlider,
            jMarketsTickerSlider: jMarketsTickerSlider,
            jFurtherNewsletterSlider: jFurtherNewsletterSlider,
            jNewsletterSubscriptionSlider: jNewsletterSubscriptionSlider,
            jCurrentNewsletterSlider: jCurrentNewsletterSlider,
            jWebinareCategoriesSlider: jWebinareCategoriesSlider
        });

        initView.call(this);
    };

    function initView() {
        initNewsSlider.call(this);
        initProductsSlider.call(this);
        initTabsSlider.call(this);
        initMarketsListSlider.call(this);
        initMarketsTickerSlider.call(this);
        initFurtherNewsletterSlider.call(this);
        initNewsletterSubscriptionSlider.call(this);
        initCurrentNewsletterSlider.call(this);
        initWebinareCategoriesSlider.call(this);
    }

    function initWebinareCategoriesSlider() {
        var that = this;

        if(that.jWebinareCategoriesSlider.length) {
            var jAmount = jQuery(".webinare-categories__pages-amount");
            var jPrev = jQuery(".webinare-categories__pagination-prev");
            var jNext = jQuery(".webinare-categories__pagination-next");

            that.jWebinareCategoriesSlider.on('afterChange', function (event, slick) {
                var slide = slick.currentSlide + 1;
                var slidesAmount = slick.slideCount;
                var jPaginationNumber = jQuery(".webinare-categories__pagination-number");
                var jPagesNumber = jQuery(".webinare-categories__pages-current");

                slide > 1 ? jPrev.addClass("active") : jPrev.removeClass("active");
                slide < slidesAmount ? jNext.addClass("active") : jNext.removeClass("active");

                jAmount.text(slidesAmount);
                jPaginationNumber.text(slide);
                jPagesNumber.text(slide);
            });

            that.jWebinareCategoriesSlider.slick({
                 arrows: false,
                 adaptiveHeight: true,
                 fade: true,
                 infinite: false,
                 slidesToScroll: 1,
                 slidesPerRow: 3,
                 speed: 200,
                 focusOnSelect: true,
                 pauseOnHover: false,
                 dots: false,
                 responsive: [{
                     breakpoint: 768,
                     settings: {
                         slidesPerRow: 2
                     }
                 },{
                     breakpoint: 481,
                     settings: {
                         slidesPerRow: 1
                     }
                 }]
             });

            var oSlick = that.jWebinareCategoriesSlider.slick("getSlick");
            var slidesCount = oSlick.slideCount;

            jAmount.text(slidesCount);

            if(slidesCount > 1) {
                jNext.addClass("active");
            }

            jPrev.on("click", function (event) {
                event.preventDefault();

                that.jWebinareCategoriesSlider.slick("slickPrev");
            });

            jNext.on("click", function (event) {
                event.preventDefault();

                that.jWebinareCategoriesSlider.slick("slickNext");
            });
        }
    }
    
    function initNewsletterSubscriptionSlider() {
        if(this.jNewsletterSubscriptionSlider.length) {
            this.jNewsletterSubscriptionSlider.slick({
                adaptiveHeight: true,
                infinite: false,
                arrows: false,
                speed: 200,
                slidesToShow: 2,
                slidesToScroll: 1,
                dots: false,
                rows: 0,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        dots: true,
                        slidesToShow: 1
                    }
                }]
            });
        }
    }
    
    function initCurrentNewsletterSlider() {
        if(this.jCurrentNewsletterSlider.length) {
            var options = {
                mobileFirst: true,
                adaptiveHeight: true,
                arrows: false,
                speed: 200,
                infinite: false,
                slidesToShow: 1,
                slidesToScroll: 1,
                dots: true,
                responsive: [{
                    breakpoint: 768,
                    settings: "unslick"
                }]
            };

            this.jCurrentNewsletterSlider.slick(options);
        }
    }
    
    function initFurtherNewsletterSlider() {
        var that = this;
        
        if(that.jFurtherNewsletterSlider.length) {
            var jAmount = jQuery(".current-newsletter__pages-amount");
            var jPrev = jQuery(".current-newsletter__pagination-prev");
            var jNext = jQuery(".current-newsletter__pagination-next");
            
            that.jFurtherNewsletterSlider.on('afterChange', function (event, slick) {
                var slide = slick.currentSlide + 1;
                var slidesAmount = slick.slideCount;
                var jPaginationNumber = jQuery(".current-newsletter__pagination-number");
                var jPagesNumber = jQuery(".current-newsletter__pages-current");

                slide > 1 ? jPrev.addClass("active") : jPrev.removeClass("active");
                slide < slidesAmount ? jNext.addClass("active") : jNext.removeClass("active");

                jAmount.text(slidesAmount);
                jPaginationNumber.text(slide);
                jPagesNumber.text(slide);
            });

            that.jFurtherNewsletterSlider.slick({
                arrows: false,
                adaptiveHeight: true,
                fade: true,
                infinite: false,
                slidesPerRow: 2,
                speed: 200,
                focusOnSelect: true,
                pauseOnHover: false,
                dots: false,
                responsive: [{
                    breakpoint: 767,
                    settings: {
                        slidesPerRow: 1,
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }]
            });
            
            var oSlick = that.jFurtherNewsletterSlider.slick("getSlick");
            var slidesCount = oSlick.slideCount;
            
            jAmount.text(slidesCount);
            
            if(slidesCount > 1) {
                jNext.addClass("active");
            }
            
            jPrev.on("click", function (event) {
                event.preventDefault();
                
                that.jFurtherNewsletterSlider.slick("slickPrev");
            });
            
            jNext.on("click", function (event) {
                event.preventDefault();
                
                that.jFurtherNewsletterSlider.slick("slickNext");
            });
        }
    }

    function initMarketsListSlider() {
        if(this.jMarketsListSlider.length) {
            this.jMarketsListSlider.slick({
              arrows: false,
              adaptiveHeight: true,
              autoplay: true,
              autoplaySpeed: 3000,
              centerMode: false,
              infinite: true,
              vertical: true,
              verticalSwiping: true,
              rows: 1,
              slidesPerRow: 4,
              speed: 800,
              focusOnSelect: true,
              pauseOnHover: false,
              responsive: [{
                  breakpoint: 1561,
                  settings: {
                      slidesPerRow: 3
                  }
              }, {
                  breakpoint: 1350,
                  settings: {
                      slidesPerRow: 2
                  }
              }, {
                  breakpoint: 1100,
                  settings: {
                      slidesPerRow: 1
                  }
              }]
          });
        }
    }
    
    function initMarketsTickerSlider() {
        if(this.jMarketsTickerSlider.length) {
            this.jMarketsTickerSlider.slick({
                accessibility: false,
                arrows: false,
                autoplay: true,
                autoplaySpeed: 3000,
                draggable: false,
                dots: false,
                pauseOnHover: false,
                pauseOnFocus: false,
                infinite: true,
                speed: 1500,
                slidesToShow: 1,
                slidesToScroll: 1
            });
        }
    }
    
    function initProductsSlider(){
        if(this.jProductsSlider.length){
            this.jProductsSlider.slick({
                prevArrow: buttomTemplatePrev,
                nextArrow: buttomTemplateNext,
                infinite: false,
                speed: 200,
                slidesToShow: 3,
                slidesToScroll: 1,
                dots: false,
                responsive: [{
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        }
    }
    
    function initTabsSlider() {
        var that = this;
        
        if(that.jTabsSlider.length) {
            that.jTabsSlider.slick({
                prevArrow: buttomTemplatePrev,
                nextArrow: buttomTemplateNext,
                arrows: false,
                infinite: false,
                speed: 500,
                slidesToShow: 4,
                slidesToScroll: 1,
                dots: false,
                variableWidth: true,
                accessibility: false,
                responsive: [{
                    breakpoint: 992,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 481,
                    settings: {
                        adaptiveHeight: true,
                        focusOnSelect: true,
                        arrows: true,
                        centerMode: true,
                        centerPadding: 0,
                        slidesToShow: 1,
                        variableWidth: false
                    }
                }]
            });
        }
    }
    
    function initNewsSlider() {
        if(this.jNewsSlider.length) {
            this.jNewsSlider.slick({
                prevArrow: buttomTemplatePrev,
                nextArrow: buttomTemplateNext,
                adaptiveHeight: true,
                infinite: false,
                speed: 200,
                slidesToShow: 3,
                slidesToScroll: 1,
                rows: 0,
                dots: true,
                responsive: [{
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: 2
                    }
                }, {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1
                    }
                }]
            });
        }
    }
    
    return Slider;
}(jQuery));