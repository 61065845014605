var delay = (function(){
    var timer = 0;
    return function(callback, ms){
        clearTimeout (timer);
        timer = setTimeout(callback, ms);
    };
})();

var sg = sg || {};

sg.VerticalSlider = sg.VerticalSlider || (function (jQuery) {
    var VerticalSlider = function (initValues) {
        var id = initValues.id;
        var jSlider = initValues.jSlider;
        var jValueInput = initValues.jValueInput;
        var items = initValues.items;

        jQuery.extend(true, this, {
            id: id,
            jSlider: jSlider,
            jValueInput: jValueInput,
            items: items
        });

        initView.call(this);
    };

    var initSlider = function() {
        var that = this;
        var count = 3;
        var centerPadding = 84;

        if(that.items === 1){
            centerPadding = 149;
        }

        that.jSlider.slick({
            prevArrow: '<button data-fs-wheel data-role="none" class="slick-prev" aria-label="Prev" role="button"></button>',
            nextArrow: '<button data-fs-wheel data-role="none" class="slick-next" aria-label="Next" role="button"></button>',
            centerMode: true,
            slidesToScroll: 1,
            swipeToSlide: true,
            centerPadding: centerPadding + "px",
            lazyLoad: 'progressive',
            infinite: true,
            vertical: true,
            verticalSwiping: true,
            speed: 200,
            slidesToShow: count,
            focusOnSelect: true
        });
    };


    var initMouseWheel = function() {
        var that = this;

        that.jSlider.on('mousewheel', function (event) {
            event.preventDefault();

            delay(function(){
                var yValue = event.deltaY;

                if(yValue > 0) {
                    that.jSlider.slick('slickNext');
                    fastSearchCtrl.sliderChanged();
                } else {
                    that.jSlider.slick('slickPrev');
                    fastSearchCtrl.sliderChanged();
                }
            }, 100 );
        });

        that.jSlider.on('swipe', function (event) {
            event.preventDefault();

            delay(function(){
                var yValue = event.deltaY;

                if(yValue > 0) {
                    that.jSlider.slick('slickNext');
                    fastSearchCtrl.sliderChanged();
                } else {
                    that.jSlider.slick('slickPrev');
                    fastSearchCtrl.sliderChanged();
                }
            }, 100 );
        });
    };

    String.prototype.replaceAt = function (index, character) {
        return this.substr(0, index) + character + this.substr(index + character.length);
    };

    var updateInput = function(number) {
        this.jValueInput.val(number);
    };

    var initEventHandler = function() {
        var that = this;

        that.jSlider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
            var jCurrentSlide = jQuery(slick.$slides[nextSlide]);
            var currentValue = jCurrentSlide.find(".number").text();

            updateInput.call(that, currentValue);
        });
    };

    var initView = function() {
        initSlider.call(this);
        initMouseWheel.call(this);
        initEventHandler.call(this);
    };

    return VerticalSlider;
}(jQuery));