"use strict";

var underlyingNameTypeFilterCtrl;
var primaryFilterCtrl;
var secondaryFilterCtrl;
var filterCtrl;
var pageCtrl;
var sortCtrl;
var excelCtrl;

jQuery(document).ready(function(){
    (function($) {
        var lang = $('html').attr('lang').toUpperCase();

        primaryFilterCtrl = new productFinder.PrimaryFilterCtrl({all : 'Alle'});
        secondaryFilterCtrl = new productFinder.SecondaryFilterCtrl();
        filterCtrl = new productFinder.FilterCtrl();
        pageCtrl = new productFinder.PageCtrl();
        sortCtrl = new productFinder.SortCtrl();
        excelCtrl = new productFinder.ExcelCtrl();
        underlyingNameTypeFilterCtrl = new productFinder.UnderlyingNameTypeFilterCtrl();

        $('.list-button-group__button-wrapper--enabled[data-value]').on('click', function(){
            underlyingNameTypeFilterCtrl.toggleUnderlyingNameTypeButton(this);
        });

        $('.list-button-group__button-wrapper--productgroup.list-button-group__button-wrapper--enabled[data-value]').on('click', function(){
            var productgroup = $(this).data('value');
            primaryFilterCtrl.selectItem('PRODUCTGROUPNAME' + lang, productgroup, false, true);
        });

    })(jQuery);
});

var productFinder = productFinder || {};

productFinder.ExcelCtrl = function () {};
productFinder.FilterCtrl = function () {};
productFinder.Helper = function(){};
productFinder.PageCtrl = function () {};
productFinder.PrimaryFilterCtrl = function () {};
productFinder.SecondaryFilterCtrl = function () {};
productFinder.SortCtrl = function () {};
productFinder.UnderlyingNameTypeFilterCtrl = function () {};

productFinder.ExcelCtrl.prototype.downloadExcel = function(downloadPath){
    this.helper = new productFinder.Helper();

    var pathname = '/de';
    if(window.location.pathname.indexOf('/en/') === 0) {pathname = '/en'}

    var params = this.helper.getSearchParameters(window.location.href);
    if(params['pageSize']) {delete params['pageSize']}
    if(params['oldPageSize']) {delete params['oldPageSize']}
    if(params['pageNumber']) {delete params['pageNumber']}
    var searchString = this.helper.stringifySearchParams(params);

    window.open(window.location.origin + pathname + downloadPath + searchString, '_self');
};

productFinder.FilterCtrl = function(){
    this.helper = new productFinder.Helper();
};

productFinder.FilterCtrl.prototype.reset = function(){
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];
    var newparams = this.getNewFilterParams();
    url = basePath + urlPath + this.helper.stringifySearchParams(newparams);
    primaryFilterCtrl.openUrl(url);
};

productFinder.FilterCtrl.prototype.getNewFilterParams = function(){
    var params = this.helper.getSearchParameters(window.location.href);
    var typeKey = '';
    var typeValue = '';

    var newparams = {};
    Object.keys(params).forEach(function(param) {
        if(param.indexOf('PRODUCTGROUP') > -1){

            newparams[param] = [params[param]];

            if([params[param]] == 'Optionsscheine'){
                typeKey = 'OPTIONSENSE';
                typeValue = 'CALL';
            }

            if([params[param]] == 'Turbos' || [params[param]] == 'Faktor+Zertifikate') {
                typeKey = 'OPTIONSENSEOPENEND';
                typeValue = 'LONG';
            }
        }
    });

    newparams[typeKey] = [typeValue];
    return newparams;
};

productFinder.Helper.prototype.getSearchParameters = function(url) {
    return url.split(/[?&]/).reduce(function(a,b,c){
        var p=b.split("="),
            k=p[0],
            v=decodeURIComponent(p[1]);
        if(!p[1])return a;
        a[k]=a[k]||[];
        a[k].push(v);
        return a;
    }, {})
};

productFinder.Helper.prototype.getSearchedItemsCount = function(params){

    var count = 0;

    if(params['sortingAttribute'] && params['sortingDirection']) {
        count = 1;
    }
    if(params['sortingAttribute2'] && params['sortingDirection2']){
        count = 2;
    }
    if(params['sortingAttribute3'] && params['sortingDirection3']){
        count = 3;
    }

    return count;
};

productFinder.Helper.prototype.stringifySearchParams = function(searchParams){
    var stringifiedSearchParams = "?";
    var first = true;

    Object.keys(searchParams).forEach(function(key) {
        if (searchParams[key]) {
            searchParams[key].forEach(function(value){
                value = encodeURIComponent(value).replace(/%20/g,'+');
                if(first){
                    stringifiedSearchParams+=key+'='+value;
                } else {
                    stringifiedSearchParams+='&'+key+'='+value;
                }
                first = false;
            });
        }
    });
    return stringifiedSearchParams;
};

productFinder.PageCtrl = function(){
    this.helper = new productFinder.Helper();
};

productFinder.PageCtrl.prototype.setPage = function(page){
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];

    var params = this.helper.getSearchParameters(url);
    params['oldPageSize']=params['pageSize'];
    delete params['pageNumber'];
    url = basePath + urlPath + this.helper.stringifySearchParams(params);
    primaryFilterCtrl.openUrl(url,'pageNumber',page);
};

productFinder.PageCtrl.prototype.setPageSize = function(element){
    var pageSize = $(element).find('option:selected').text();
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];

    var params = this.helper.getSearchParameters(url);
    params['oldPageSize'] = params['pageSize'];
    delete params['pageSize'];
    url = basePath + urlPath + this.helper.stringifySearchParams(params);
    primaryFilterCtrl.openUrl(url,'pageSize',pageSize);
};

productFinder.PrimaryFilterCtrl = function(option){
    this.helper = new productFinder.Helper();
    this.all = option.all;
    $('#dropdownMenuLink').keyup(function(e) {
        var value = $(this).val();
        $('.dropdown-item').each(function(){
            if($(this).text().toLowerCase().indexOf(value.toLowerCase())===-1) {
                $(this).hide();
            } else {
                $(this).show();
            }
        })
    })
};

productFinder.PrimaryFilterCtrl.prototype.setFamilies = function(lang, params, value, fieldNameFamily){
    params[fieldNameFamily] = [];
    switch(value){
        case 'Aktienanleihen':
            if(lang==='DE'){
                params[fieldNameFamily].push('Aktienanleihen Classic');
                //params[fieldNameFamily].push('Aktienanleihen Protect Express');
                //params[fieldNameFamily].push('Aktienanleihen Express');
                params[fieldNameFamily].push('Aktienanleihen Protect');
            }
            else{
                params[fieldNameFamily].push('Reverse Convertibles Classic');
                params[fieldNameFamily].push('Reverse Convertibles Protect');
            }
            break;
        case 'Bonus-Zertifikate':
            params[fieldNameFamily].push('Bonus Classic');
            params[fieldNameFamily].push('Bonus Capped');
            /*
             params[fieldNameFamily].push('Korridor Bonus-Zertifikate');
             params[fieldNameFamily].push('Multi Bonus-Zertifikate');
             params[fieldNameFamily].push('Reverse Bonus-Zertifikate');
             params[fieldNameFamily].push('Capped Reverse Bonus-Zertifikate');
             params[fieldNameFamily].push('Rolling Bonus-Zertifikate');
             */
            break;
        case 'Discount-Zertifikate':
            /*
             params[fieldNameFamily].push('Discount-Zertifikate classic');
             params[fieldNameFamily].push('Korridor Discount-Zertifikate');
             params[fieldNameFamily].push('Reverse Discount-Zertifikate');
             */
            break;
        case 'Express-Zertifikate':
            params[fieldNameFamily].push('Express-Zertifikate classic');
            params[fieldNameFamily].push('Fixkupon Express');
            params[fieldNameFamily].push('Duo Fixkupon Express');
            params[fieldNameFamily].push('Stufenexpress');
            params[fieldNameFamily].push('Best Express');
            params[fieldNameFamily].push('Multi Express');
            params[fieldNameFamily].push('Memory Express');
            params[fieldNameFamily].push('Duo Memory Express');
            params[fieldNameFamily].push('Multi Memory Express');
            break;
        case 'Kapitalschutz-Zertifikate':
            //params[fieldNameFamily].push('Kapitalschutz-Zertifikate classic');
            //params[fieldNameFamily].push('Reverse Kapitalschutz-Zertifikate');
            break;
        case 'Outperformance-Zertifikate':
            //params[fieldNameFamily].push('Outperformance-Zertifikate classic');
            //params[fieldNameFamily].push('Sprint-Zertifikate');
            break;
        case 'Partizipations-Zertifikate':
            if(lang==='DE'){
                params[fieldNameFamily].push('Index-Zertifikate Classic');
                params[fieldNameFamily].push('Index-Zertifikate Short');
            }
            else{
                params[fieldNameFamily].push('Index Certificates Classic');
                params[fieldNameFamily].push('Index Certificates Short');
            }
            break;
        default:
            break;
    }

    return params;
};

productFinder.PrimaryFilterCtrl.prototype.selectItem = function(fieldName, value, multiselect, isProductGroup){
    var ctrl = primaryFilterCtrl;

    if(multiselect){

        ctrl.openUrlWithMultiField(fieldName, value);
    } else {
        ctrl.openUrlWithoutMultiField(fieldName, value, isProductGroup);
    }
};

productFinder.PrimaryFilterCtrl.prototype.openUrlWithMultiField = function(fieldName, value){
    var ctrl = primaryFilterCtrl;
    var url = window.location.href;


    var newurl = url.replace("&"+fieldName+"="+encodeURIComponent(value), '');

    if(newurl===url){
        newurl = url.replace("&"+fieldName+"="+encodeURIComponent(value).replace(/%20/g,'+'), '');
    }

    var urlPath = newurl.split('?')[0];
    var params = this.helper.getSearchParameters(newurl);

    var checkboxChecked = false;

    if (fieldName.indexOf('FAMILY') > -1){
        var checkedCheckboxes = $('.list-filter__type-checkbox input:checked');
        params['FAMILYDE'] = [];
        checkboxChecked = true;

        if(checkedCheckboxes.length !== 0){
            checkedCheckboxes.each(function(index, element){
                params['FAMILYDE'].push(element.getAttribute('id'));
            });
        } else {
            var checkboxes = $('.list-filter__type-checkbox input');

            checkboxes.each(function(index, element){
                params['FAMILYDE'].push(element.getAttribute('id'));
            });
        }
    }

    if(params[fieldName] && params[fieldName].indexOf("Alpha-Optionsschein")>-1){
        params[fieldName].splice(params[fieldName].indexOf("Alpha-Optionsschein"),1);
    }
    if(params[fieldName] && params[fieldName].indexOf("Alpha Warrant")>-1){
        params[fieldName].splice(params[fieldName].indexOf("Alpha Warrant"),1);
    }
    if(params[fieldName] && params[fieldName].indexOf("Hamster-Optionsschein")>-1){
        params[fieldName].splice(params[fieldName].indexOf("Hamster-Optionsschein"),1);
    }
    if(params[fieldName] && params[fieldName].indexOf("Hamster Warrant")>-1){
        params[fieldName].splice(params[fieldName].indexOf("Hamster Warrant"),1);
    }

    var newurl2 = urlPath + this.helper.stringifySearchParams(params);

    if(url===newurl && !checkboxChecked){
        ctrl.openUrl(newurl2, fieldName, value);
    } else {
        ctrl.openUrl(newurl2);
    }
};

productFinder.PrimaryFilterCtrl.prototype.openUrlWithoutMultiField = function(fieldName, value, isProductGroup){
    var ctrl = primaryFilterCtrl;
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];

    var params = this.helper.getSearchParameters(url);
    delete params[fieldName];

    if(isProductGroup){
        var lang = fieldName.slice(-2);
        var fieldNameFamily = 'FAMILY'+lang;
        delete params[fieldNameFamily];
        params = ctrl.setFamilies(lang, params, value, fieldNameFamily);
    }
    if(value===ctrl.all){
        params[fieldName] = [];
        $('.dropdown-item-family').each(function(index, familyItem){
            params[fieldName].push($(familyItem).text());
        });
        url = basePath + urlPath + this.helper.stringifySearchParams(params);
        ctrl.openUrl(url);
    } else {
        url = basePath + urlPath + this.helper.stringifySearchParams(params);
        ctrl.openUrl(url, fieldName, value);
    }
};

productFinder.PrimaryFilterCtrl.prototype.openUrl = function(url, fieldName, value){
    url = url.replace('#','');

    if(fieldName && value){
        url = url+'&'+fieldName+'='+encodeURIComponent(value);
    }

    window.open(url.replace(/%2B/g,'+').replace(/%20/g,'+'), '_self');
};

productFinder.SecondaryFilterCtrl = function(){
    var ctrl = this;
    this.helper = new productFinder.Helper();
    var jListFilterSliders = jQuery(".list-filter__slider");

    for(var lfs = 0; lfs < jListFilterSliders.length; lfs++) {
        var jListFilterSlider = jQuery(jListFilterSliders[lfs]);
        jListFilterSlider.on("userValuesChanged", function(e, data){
            var attributeId = $(this).data('attribute');
            var type = $(this).data('type');
            var dateExtension = '';
            if(type === 'date'){
                dateExtension = 'Date'
            }

            var valueMin = ctrl.getSliderValue(data.values.min);
            var valueMax = ctrl.getSliderValue(data.values.max);
            ctrl.openUrlWithSliderValue(attributeId+'-min'+dateExtension, valueMin, attributeId+'-max'+dateExtension, valueMax);
        })
    }
    var url = window.location.href;
    var isOpenEnd = url.indexOf(encodeURI('Open End')) > -1;
    if(!isOpenEnd){
        $('.list-filter__range-input--placeholder').remove();
    }

    var clearFixTablet = $('<div class="clearfix visible-tablet"></div>');
    $('.list-filter__range-wrapper:first').after(clearFixTablet);
};

productFinder.SecondaryFilterCtrl.prototype.changeSliderItem = function(fieldName, element){
    var ctrl = secondaryFilterCtrl;
    var type = $(element).closest('.list-filter__range-wrapper').find('.list-filter__slider').data('type');

    var value = ctrl.getSliderValue($(element).val(), type);
    ctrl.openUrlWithSliderValue(fieldName, value);
};

productFinder.SecondaryFilterCtrl.prototype.getSliderValue = function(element, type){
    var value;

    if(typeof element === 'number' && !type){
        value = element;
    } else if (element.toLocaleDateString != undefined  && !type){
        value = element.getDate() + '.' + (element.getMonth()+1) + '.' + element.getFullYear();
    } else {
        if(type && type.indexOf('number') > -1){
            value = element;
            if(ENVIRONMENT.language.lowercase === 'de') value = value.replace('.','');
            if(ENVIRONMENT.language.lowercase === 'en') value = value.replace(',','');
        } else if(type && type === 'date'){

            var dt1   = parseInt(element.substring(0,2));
            var mon1  = parseInt(element.substring(3,5));
            var yr1   = parseInt(element.substring(6,10));

            var date = new Date(yr1, mon1-1, dt1);
            value = date.getDate() + '.' + (date.getMonth()+1) + '.' + date.getFullYear();
        }
    }

    return value;
};

productFinder.SecondaryFilterCtrl.prototype.openUrlWithSliderValue = function(fieldName, value, fieldName2, value2){
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];

    var params = this.helper.getSearchParameters(url);

    delete params[fieldName];
    if(fieldName2){
        delete params[fieldName2];
    }

    url = urlPath + this.helper.stringifySearchParams(params);

    var params = '&' + fieldName + '=' + value;

    if(fieldName2 && value2){
        params += '&' + fieldName2 + '=' + value2;
    }

    url = url.replace(new RegExp('%2B', 'g'), '+');

    window.open(basePath + url + params, '_self');
};

productFinder.SortCtrl = function(){
    this.helper = new productFinder.Helper();
    var url = window.location.href;
    var params = this.helper.getSearchParameters(url);

    var attributeId;
    var sortDirection;

    if(params['sortingAttribute'] && params['sortingDirection']){
        attributeId = params['sortingAttribute'][0];
        sortDirection = params['sortingDirection'][0];

        $('#'+attributeId).addClass('sorting--'+sortDirection.toLowerCase()).attr('data-sorting-index', 1);
    }

    if(params['sortingAttribute2'] && params['sortingDirection2']){
        attributeId = params['sortingAttribute2'][0];
        sortDirection = params['sortingDirection2'][0];

        $('#'+attributeId).addClass('sorting--'+sortDirection.toLowerCase()).attr('data-sorting-index', 2);
    }

    if(params['sortingAttribute3'] && params['sortingDirection3']){
        attributeId = params['sortingAttribute3'][0];
        sortDirection = params['sortingDirection3'][0];

        $('#'+attributeId).addClass('sorting--'+sortDirection.toLowerCase()).attr('data-sorting-index', 3);
    }

    this.setLocalStrings();
};

productFinder.SortCtrl.prototype.setLocalStrings = function(){
    var title = ENVIRONMENT.language.lowercase === 'de' ? 'Sortierung aufheben' : 'Clear Sorting';
    $('.sorting-control__clear').attr('title', title);
};

productFinder.SortCtrl.prototype.sort = function(element, fieldName){
    var ctrl = sortCtrl;
    var $element = $(element);
    var sortIndex = $element.data('sorting-index');

    if($element.hasClass('sorting--desc')){
        $element.removeClass('sorting--desc');
        $element.addClass('sorting--asc');
        ctrl.sendSort('ASC',fieldName,sortIndex);
    }
    else if($element.hasClass('sorting--asc')){
        $element.removeClass('sorting--asc');
        $element.addClass('sorting--desc');
        ctrl.sendSort('DESC',fieldName,sortIndex);
    }
    else{
        $element.addClass('sorting--desc');
        ctrl.sendSort('DESC',fieldName,sortIndex);
    }
};

productFinder.SortCtrl.prototype.sendSort = function(direction, fieldName, sortIndex, clear){
    var url = window.location.href;
    var urlPath = window.location.search.split('?')[0];
    var basePath = window.location.href.split('?')[0];

    var params = this.helper.getSearchParameters(url);

    var searchedItemsCount = this.helper.getSearchedItemsCount(params);

    if(clear === undefined){
        if(sortIndex && searchedItemsCount !== 0) {
            if(sortIndex === 2){
                params['sortingAttribute2']=[fieldName];
                params['sortingDirection2']=[direction];
            }else if(sortIndex === 3){
                params['sortingAttribute3']=[fieldName];
                params['sortingDirection3']=[direction];
            }else{
                params['sortingAttribute']=[fieldName];
                params['sortingDirection']=[direction];
            }
        } else {
            if(searchedItemsCount === 0) {
                params['sortingAttribute']=[fieldName];
                params['sortingDirection']=[direction];
            }else if (searchedItemsCount === 1){
                params['sortingAttribute2']=[fieldName];
                params['sortingDirection2']=[direction];
            }else{
                params['sortingAttribute3']=[fieldName];
                params['sortingDirection3']=[direction];
            }
        }
    }else{
        if(sortIndex == searchedItemsCount) {
            delete params['sortingAttribute'+sortIndex];
            delete params['sortingDirection'+sortIndex];
        }else if(sortIndex == 2) {
            params['sortingAttribute2'] = params['sortingAttribute3'];
            params['sortingDirection2'] = params['sortingDirection3'];

            delete params['sortingAttribute3'];
            delete  params['sortingDirection3'];
        }else if(sortIndex == 1) {
            params['sortingAttribute'] = params['sortingAttribute2'];
            params['sortingDirection'] = params['sortingDirection2'];

            if(params['sortingAttribute3'] && params['sortingDirection3']){
                params['sortingAttribute2'] = params['sortingAttribute3'];
                params['sortingDirection2'] = params['sortingDirection3'];

                delete params['sortingAttribute3'];
                delete  params['sortingDirection3'];
            }else{
                delete params['sortingAttribute2'];
                delete  params['sortingDirection2'];
            }
        }
    }

    url = basePath + urlPath + this.helper.stringifySearchParams(params);
    primaryFilterCtrl.openUrl(url);
};

productFinder.SortCtrl.prototype.clear = function(element, event){
    event.stopPropagation();

    var parentIndex = $($(element).closest("th[data-sorting-index]")).attr('data-sorting-index');
    this.sendSort(null,null,parentIndex,true);
};

productFinder.UnderlyingNameTypeFilterCtrl = function(){
    this.helper = new productFinder.Helper();
    this.searchParams = this.helper.getSearchParameters(window.location.search);

    if(this.searchParams.underlyingNameType){
        this.searchParams.underlyingNameType.forEach(function(underlyingNameType){
            $('.list-button-group__button-wrapper[data-value="' + underlyingNameType.replace('+',' ') + '"]').addClass('list-button-group__button-wrapper--active');
        })
    }
};

productFinder.UnderlyingNameTypeFilterCtrl.prototype.toggleUnderlyingNameTypeButton = function(element){
    var ctrl = this;
    var url = window.location.href.split('?')[0];

    var underlyingNameType = $(element).attr('data-value');

    if($(element).hasClass("list-button-group__button-wrapper--active")){
        delete ctrl.searchParams.underlyingNameType;
    } else {
        $('.list-button-group__button--active').removeClass("list-button-group__button-wrapper--active");
        $(element).addClass("list-button-group__button-wrapper--active");

        var newParams = new productFinder.FilterCtrl().getNewFilterParams();
        newParams.underlyingNameType = [underlyingNameType];

        ctrl.searchParams = newParams;
    }

    if(!ctrl.searchParams.pageSize)
        ctrl.searchParams.pageSize = ['25'];
    if(!ctrl.searchParams.oldPageSize)
        ctrl.searchParams.oldPageSize = ['25'];
    ctrl.searchParams.pageNumber = ['0'];

    var fullUrl = url + ctrl.helper.stringifySearchParams(ctrl.searchParams);

    window.open(fullUrl, '_self');
};

jQuery(document).ready(function() {
    jQuery('.list-filter__range-input input').keypress(function(event){
        var keyCode = (event.keyCode ? event.keyCode : event.which);

        if(keyCode == 13) this.onchange();
    });
});
