var sg = sg || {};

sg.Resize = new (function (jQuery) {
    var resizeCheckInterval = 500;
    var screenSizeHasChanged = false;
    var functions;
    var jWindow = jQuery(window);

    var Resize = function () {
        functions = [];
        screenSizeHasChanged = false;
        jWindow.resize(onResize);
    };

    Resize.prototype.addFunction = function (fkt, condition) {
        if (fkt instanceof Function) {
            functions.push({
                fkt: fkt,
                condition: condition
            });

            return true;
        }

        return false;
    };

    Resize.prototype.clear = function () {
        functions = [];
    };

    var onResize = function () {
        screenSizeHasChanged = true;
        window.setTimeout(checkResizeFunctions, resizeCheckInterval);
        jWindow.unbind("resize", onResize);
    };

    var checkResizeFunctions = function () {
        if (screenSizeHasChanged) {
            for (var i = 0; i < functions.length; i++) {
                if (!(functions[i].condition instanceof Function) || ((functions[i].condition instanceof Function) && functions[i].condition(jWindow))) {
                    functions[i].fkt();
                }
            }

            jWindow.resize(onResize);
        }
    };

    return Resize;
}(jQuery));