var underlyingSearchController;

var productGroupLink = '/productgroups.html';
var productFinderLink = '/finder.html';

$(function(){
    if ($('[data-search-dropdown]').length) {
        underlyingSearchController = new queo.UnderlyingSearchController();
    }
});

var queo = queo || {};

queo.UnderlyingSearchController = function () {
    var ctrl = this;
    ctrl.inputValue = $('[data-search-dropdown-input]').val();

    $('[data-search-dropdown] [data-toggle="collapse"]').on("click", ctrl.onClickSubMenuHeader);

    $(document).on('click', function(){
        $('[data-search-dropdown]').removeClass('open');
        $('[data-search-dropdown] .collapse').collapse('hide');
    });

    $('[data-search-dropdown]').click(function(e){
        e.stopPropagation();

        var $this = $(this);

        if(!$this.hasClass('open')) {
            $this.addClass('open');
        }
        $($this.find('.dropdown-header')[0]).next().collapse('show');
    });

    $('[data-search-dropdown-input]')
        .on('focus', function(){
            ctrl.inputValue = $(this).val();
            $(this).val('');
            ctrl.filterUnderlyings(this, $(this).val());
        })
        .on('blur',function(){
            $(this).val(ctrl.inputValue);
        })
        .keyup(function(){
            ctrl.filterUnderlyings(this, $(this).val());

            $(this).closest('[data-search-dropdown]').find('.collapse').collapse('show');
            $('[data-search-dropdown] .collapse').collapse('show');
        });

    $('[data-search-dropdown-input="header-search"]').closest('[data-search-dropdown]').find('a.dropdown-item').on('click', function(){
        selectDropdownItem(this);
    });
};

queo.UnderlyingSearchController.prototype.filterUnderlyings = function (that, underlyingName) {
    $(that).closest('[data-search-dropdown]').find('.dropdown-menu .dropdown-item').each(function(index, value){
        if($(value).text().toLowerCase().indexOf(underlyingName.toLowerCase())===-1){
            $(value).hide();
        } else {
            $(value).show();
        }
    });
};

queo.UnderlyingSearchController.prototype.onClickSubMenuHeader = function (event) {
    event.stopPropagation();
    event.preventDefault();

    var jThis = jQuery(this);
    var jDropdown = jThis.closest(".dropdown");
    jDropdown.addClass("open");

    var collapseId = jThis.attr("href");
    jQuery(collapseId).collapse("toggle");
};

function filterList(element){
    var value = $(element).val();
    $('.header__search a.dropdown-item').each(function(){
        if($(this).text().toLowerCase().indexOf(value.toLowerCase())===-1){
            $(this).hide();
        }
        else{
            $(this).show();
        }
    })
}

function selectDropdownItem(element){
    var value = $(element).text();
    $('.header__search .dropdown-toggle').val(value);
    openUrlPath(productGroupLink, 'underlyingName', value);
}

function submit(event, element){
    console.log(event);

    if(event.keyCode == 13){

        var value = $(element).val().trim();
        $.ajax({
           url: '/bcs_ajax/search',
           type: 'get',
           dataType: 'text',
           data: {
               wknOrIsin: value
           },
           crossDomain: true,
           success: function(response){
               console.log(response);
               success(response);
           },
           error: function(response){
               error(value);
           }
       });
    }
}

function success(response){
    openUrlPath(productFinderLink, 'ISINWKNKEYWORD', response);
}

function error(value){
    var underlyingNameFound = false;

    $('.header__search a.dropdown-item').each(function(){
        var underlyingName = $(this).text();

        if(underlyingName.toLowerCase() === value.toLowerCase()){
            underlyingNameFound = true;
            value = underlyingName;
        }
    });

    if (underlyingNameFound) {
        openUrlPath(productGroupLink, 'underlyingName', value);
    } else {
        openUrlPath(productGroupLink, 'isinWknKeyword', value);
    }
}

function openUrlPath(baseLink, fieldName, value){
    var url = baseLink;

    if (fieldName && value) {
        url += '?' + fieldName + '=' + encodeURIComponent(value);
    }
    window.open(url, '_self');
}
