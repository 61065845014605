var sg = sg || {};

sg.ResponsiveHelpers = sg.ResponsiveHelpers || (function ($) {
    var ResponsiveHelpers = function (initValues) {
        initView.call(this);
    };
    
    var resizeHandler = function resizeHandler() {
        var windowWidth = $(window).width();
        var $documentsList = $(".documents__list");

        windowWidth <= 1024 ? $documentsList.addClass("dropdown-menu") : $documentsList.removeClass("dropdown-menu");
    };
    
    var initView = function initView() {
        //-----> inital check
        resizeHandler();

        //-----> check on resize
        sg.Resize.addFunction(resizeHandler);
    };
    
    return ResponsiveHelpers;
}(jQuery));